import React, { MouseEventHandler, useEffect, useState } from "react";
import {
  Collapse,
  Dialog,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import OpenEventIcon from "@mui/icons-material/Event";
import EventCreateIcon from "@mui/icons-material/LibraryAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import { Trans, useTranslation } from "react-i18next";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import store, { RootState, useTSelector } from "rx/store";
import { setEventId } from "rx/eventIdSlice";
import OpenEventDialog from "./OpenEventDialog";
import { DialogNames, openDialog } from "rx/dialogsSlice";
import EventResultDialog from "components/EventResultDialog";
import {
  Category,
  LockOpen,
  Timeline,
  SystemUpdateAlt,
  ExpandLess,
  ExpandMore,
  FormatListNumbered,
  Speed,
  VpnKeyRounded,
  Explore,
  LocationSearching,
  LocationDisabled,
  FlagOutlined,
  NotListedLocation,
  FileCopy,
  DeleteSweep,
  RestoreFromTrash,
  Print,
  EmojiEvents,
  Download,
  Poll,
  Password,
  Translate,
  Announcement,
  ReceiptLong,
  HowToReg,
} from "@mui/icons-material";
import Groups from "icons/Groups";
import { argouid } from "utils/SmallUtils";
import UpgradeDialog from "./UpgradeDialog";
import TracksMenu from "./TracksMenu";
import {
  setDrawerMenuContent,
  drawerMenuContentTypes,
  setShowKPs,
  setShowMiniMap,
  setTrackMe,
  toggleShowMarks,
  enableAddKP,
  showCupResult,
} from "rx/appStateSlice";
import DrawerHeader from "./DrawerHeader";
import Architecture from "icons/Architecture";
import Engineering from "icons/Engineering";
import CreateEvent from "./CreateEvent";
import { useNL } from "utils/NLContext";
import { archiveEvent, clearEvent, haveDeletedEvents } from "utils/archive";
import { execWithProgress } from "rx/saveInProgressSlice";
import dayjs from "dayjs";
import AddKP from "icons/AddKP";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { getDatabase, onValue, ref } from "firebase/database";

/*
let fec = 0;
const FullEvent: React.FC = () => {
  fec += 1;
  const fullev = useSelector((state: RootState) => state.event);
  console.log("going through", fullev, fec);
  return (
    <div>
      {fec} - {JSON.stringify(fullev)}
    </div>
  );
};

let fec2 = 0;
const NameEvent: React.FC = () => {
  const fullev = useSelector((state: RootState) => state.event.name);
  console.log("going through name", fullev, fec);
  return (
    <div>
      {++fec2} - {JSON.stringify(fullev)}
    </div>
  );
};
*/
/*
const DrawerItem: React.FC<{
  onClick: MouseEventHandler;
  nested: boolean;
}> = (props) => {
  let addprops: { button?: true } = {};
  if (props.onClick !== null) addprops.button = true;
  return (
    <ListItem
      {...addprops}
      className={props.nested ? props.classes.nested : null}
      onClick={props.onClick}
    >
      {props.icon !== undefined && (
        <ListItemIcon>
          <Icon>{props.icon}</Icon>
        </ListItemIcon>
      )}
      {props.firstaction !== undefined && props.firstaction}
      {props.counter !== undefined && props.counter > 0 ? (
        <CounterBadge content={itemtext} count={props.counter} />
      ) : (
        itemtext
      )}
      {props.expanded !== undefined && (
        <Icon>{props.expanded ? "expand_less" : "expand_more"}</Icon>
      )}
      {props.secondaryAction && (
        <ListItemSecondaryAction>
          {props.secondaryAction}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
*/

const useHasTranslator = () => {
  const uid = useTSelector((state) => state.user.user?.uid);
  const [hasAccess, setHasAccess] = useState(false);
  useEffect(() => {
    if (!uid) {
      setHasAccess(false);
      return;
    }
    return onValue(ref(getDatabase(), "/conf/translators/" + uid), (s) => {
      setHasAccess(s.exists());
    });
  }, [uid]);
  return hasAccess;
};

const TranslatorToolMenuItem = () => {
  const dispatch = useDispatch();
  const hasAccess = useHasTranslator();
  return hasAccess ? (
    <DrawerItem
      aria-label="Translations tool"
      key="trans"
      icon={<Translate />}
      text={"menu.translatortool"}
      onClick={() => dispatch(openDialog("translatorTool"))}
    />
  ) : null;
};
const CupMenuItem: React.FC<{ eventid: string }> = ({ eventid }) => {
  const cupid = useRFirebaseDb<string>(`/cups/cupevents/${eventid}/m`);
  const dispatch = useDispatch();
  if (!cupid) return null;
  return (
    <DrawerItem
      aria-label="Event's cup"
      text={"menu.eventcup"}
      icon={<EmojiEvents />}
      onClick={() => dispatch(showCupResult(cupid))}
    />
  );
};
const DrawerItem: React.FC<{
  icon?: JSX.Element;
  text: string;
  expanded?: boolean;
  "aria-label": string;
  onClick: MouseEventHandler;
}> = (props) => (
  <ListItemButton aria-label={props["aria-label"]} onClick={props.onClick}>
    {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
    <ListItemText>
      <Trans>{props.text}</Trans>
    </ListItemText>
    {props.expanded !== undefined &&
      (props.expanded ? <ExpandLess /> : <ExpandMore />)}
  </ListItemButton>
);

const DeleteDrawerItem: React.FC = () => {
  const nl = useNL();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <DrawerItem
      aria-label="Delete event"
      key="delevent"
      icon={<DeleteSweep />}
      text={"menu.deleteevent"}
      onClick={() => {
        const state = store.getState();

        const evname = state.event.name;
        const evid = state.eventId;
        const uid = state.user.user?.uid;
        nl.alertDialog({
          title: t("deleteevent.title"),
          content: t("deleteevent.content", { evname: evname }),
        }).then((v) => {
          if (
            evid === null ||
            evid.length === 0 ||
            uid == null ||
            uid.length === 0
          )
            return;
          if (v !== "ok") return;

          // dispatch does not support thunks per default : https://stackoverflow.com/questions/60049490/argument-of-type-thunkaction-is-not-assignable-to-parameter-of-type-anyaction
          // Same thing is in RestoreEvent
          dispatch<any>(
            execWithProgress(() =>
              archiveEvent(evid, "deletedevents/" + uid, () => {}).then(
                async () => {
                  await clearEvent(evid, () => {}, true);
                  dispatch(setEventId(null));
                  window.history.pushState(null, "Title for page", "/");
                }
              )
            )
          );
        });
      }}
    />
  );
};

const ConditionalRestoreEvItem: React.FC = () => {
  const dispatch = useDispatch();
  const [haveevents, setHaveEvents] = useState(false);
  const uid = useTSelector((state) => state.user.user?.uid);
  useEffect(() => {
    haveDeletedEvents().then((v) => setHaveEvents(v));
  }, [uid]);

  if (!haveevents) return null;
  return (
    <DrawerItem
      aria-label="Restore event"
      key="restoreevent"
      icon={<RestoreFromTrash />}
      text={"menu.restoreevent"}
      onClick={() => {
        dispatch(openDialog("restoreEvent"));
      }}
    />
  );
};

const mapState = (state: RootState) => ({
  eventId: state.eventId,
  archived: Boolean(state.event.arch),
  uid: state.user?.user?.uid,
  teamsList: state.teamsList,
  hideresults: state.event.hideresults,
  hidetracks: state.event.hidetracks,
  hidespeedings: state.event.hidespeedings,
  eventbounds: state.event.eventbounds,
  joinrequiresconfirmation: state.event.joinrequiresconfirmation,
  kpsinweb: state.event.kpsinweb,
  eventended: state.event.endtime && dayjs().isAfter(state.event.endtime),
  showkps: state.appState.showKPs,
  trackme: state.appState.trackMe,
  showminimap: state.appState.showMiniMap,
  isLoggedIn: Boolean(state.user.user),
  isAdmin: Boolean(state.user.eventAccess),
  showMarks: state.appState.showMarks,
  addingKP: state.appState.addingKP,
});

const mapDispatch = {
  setEventId: (evid: string | null) => setEventId(evid),
  openEventSettings: () => openDialog("eventSettings"),
  openKPStats: () => openDialog("kpStats"),
  openKPData: () => openDialog("kpData"),
  openSpeedPenalties: () =>
    setDrawerMenuContent(drawerMenuContentTypes.SpeedPenalties),
  openTeamsData: () => openDialog("teamsData"),
  openDownloads: () => openDialog("downloads"),
  openTeamsAllow: () => openDialog("teamsAllow"),
  openCreateEvent: () => openDialog("createEvent"),
  openAccessCodes: () => openDialog("accessCodes"),
  openJoinCodes: () => openDialog("joinCodes"),
  openSeriesMgmt: () => openDialog("cupMgmt"),
  openRegistered: () => openDialog("registered"),
  openDialog: (w: DialogNames) => openDialog(w),
  openSpeedAreas: () => setDrawerMenuContent(drawerMenuContentTypes.SpeedAreas),
  openPrinting: () => setDrawerMenuContent(drawerMenuContentTypes.Printing),
  onSpeedingsAdmin: () =>
    setDrawerMenuContent(drawerMenuContentTypes.Speedings),
  setShowKps: (v: boolean) => setShowKPs(v),
  setShowMiniMap: (v: boolean) => setShowMiniMap(v),
  setTrackMe: (v: boolean) => setTrackMe(v),
  toggleShowMarks: () => toggleShowMarks(),
  enableAddKP: (v: boolean) => enableAddKP(v),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export type NutilogiDrawerProps = PropsFromRedux & {
  closeDrawer: () => void;
};
type NutilogiDrawerState = {
  openEventDialogOpen: boolean;
  resultDialogOpen: boolean;
  upgradeDialogOpen: boolean;
  tracksMenuExpanded: boolean;
  duplicateEvent: boolean;
};
class NutilogiDrawer extends React.Component<
  NutilogiDrawerProps,
  NutilogiDrawerState
> {
  state: NutilogiDrawerState = {
    openEventDialogOpen: false,
    resultDialogOpen: false,
    upgradeDialogOpen: false,
    tracksMenuExpanded: false,
    duplicateEvent: false,
  };
  openEvent = () => {
    this.setState({ openEventDialogOpen: true });
  };
  render() {
    const {
      isLoggedIn,
      isAdmin,
      eventId,
      hideresults,
      kpsinweb,
      uid,
      eventbounds,
      teamsList,
      archived,
    } = this.props;
    let listitems = [];
    listitems.push(
      <DrawerItem
        aria-label="Open Event"
        key="openev"
        icon={<OpenEventIcon />}
        text={"menu.openevent"}
        onClick={this.openEvent}
      />
    );

    isLoggedIn &&
      listitems.push(
        <DrawerItem
          aria-label="Create Event"
          key="createev"
          icon={<EventCreateIcon />}
          text={"menu.createevent"}
          onClick={this.props.openCreateEvent}
        />
      );
    listitems.push(<Divider key="div2" />);
    const ct = new Date().getTime() - 600000;

    if (
      Object.values(teamsList).find(
        (team) => !team.disabled && team.starttime && team.starttime > ct
      ) !== undefined
    ) {
      // have unstarted contestants, enable start menu
      listitems.push(
        <DrawerItem
          aria-label="StartList"
          key="startlist"
          text={"menu.startList"}
          icon={<FlagOutlined />}
          onClick={() => this.props.openDialog("startOrder")}
        />
      );
    }
    if (eventId) {
      (!hideresults || isAdmin) &&
        listitems.push(
          <DrawerItem
            aria-label="Result"
            key="result"
            text={"menu.result"}
            icon={<FormatListNumbered />}
            onClick={() => this.setState({ resultDialogOpen: true })}
          />
        );

      (!hideresults || isAdmin) &&
        listitems.push(
          <DrawerItem
            aria-label="KP statistics"
            key="kpstats"
            text={"menu.kpstats"}
            icon={<Poll />}
            onClick={this.props.openKPStats}
          />
        );
      listitems.push(<CupMenuItem eventid={eventId} key="evcup" />);
      (kpsinweb || isAdmin) &&
        listitems.push(
          <DrawerItem
            aria-label="Show KP's"
            key="kpsonmap"
            icon={<Category />}
            text={this.props.showkps ? "menu.hidekps" : "menu.showkps"}
            onClick={() => {
              this.props.setShowKps(!this.props.showkps);
            }}
          />
        );
      eventbounds !== undefined &&
        listitems.push(
          <DrawerItem
            aria-label="Show Event bounds minimap"
            key="boundsminimap"
            icon={<Explore />}
            text={
              this.props.showminimap ? "menu.hideminimap" : "menu.showminimap"
            }
            onClick={() => {
              this.props.setShowMiniMap(!this.props.showminimap);
            }}
          />
        );

      !this.props.hidespeedings &&
        listitems.push(
          <DrawerItem
            aria-label="Speedings Penalties"
            key="speedings"
            text={"menu.spdpenaltys"}
            icon={<Speed />}
            onClick={this.props.openSpeedPenalties}
          />
        );

      listitems.push(
        <DrawerItem
          aria-label="Enable tracking"
          key="enabletracking"
          icon={
            this.props.trackme ? <LocationSearching /> : <LocationDisabled />
          }
          text={this.props.trackme ? "menu.stoptrackme" : "menu.starttrackme"}
          onClick={() => {
            this.props.setTrackMe(!this.props.trackme);
          }}
        />
      );
      if (!this.props.hidetracks || isAdmin) {
        listitems.push(
          <DrawerItem
            aria-label="GPS tracks"
            key="tracks"
            icon={<Timeline />}
            text={"menu.gpstracks"}
            expanded={this.state.tracksMenuExpanded}
            onClick={() =>
              this.setState({
                tracksMenuExpanded: !this.state.tracksMenuExpanded,
              })
            }
          />
        );
        listitems.push(
          <Collapse
            key="teamscollapse"
            in={this.state.tracksMenuExpanded}
            unmountOnExit
          >
            <TracksMenu />
          </Collapse>
        );
      }
      if (isAdmin) {
        listitems.push(<Divider key="div1" />);
        listitems.push(
          <ListSubheader key="admhdr" disableSticky={true}>
            <Trans>menu.hdr.admin</Trans>
          </ListSubheader>
        );
        if (!archived) {
          listitems.push(
            <DrawerItem
              aria-label="Event Settings"
              key="evset"
              icon={<SettingsIcon />}
              text={"menu.eventsettings"}
              onClick={this.props.openEventSettings}
            />
          );
          listitems.push(
            <DrawerItem
              aria-label="KP Data"
              key="kpdata"
              icon={<Category />}
              text={"menu.kpdata"}
              onClick={this.props.openKPData}
            />
          );
          listitems.push(
            <DrawerItem
              aria-label="Add KP"
              key="addkp"
              icon={<AddKP />}
              text={this.props.addingKP ? "menu.canceladdkp" : "menu.addkp"}
              onClick={() => {
                this.props.enableAddKP(!this.props.addingKP);
              }}
            />
          );
          listitems.push(
            <DrawerItem
              aria-label="Join Codes"
              key="addCode"
              icon={<Password />}
              text={"menu.joincodes"}
              onClick={this.props.openJoinCodes}
            />
          );
          if (false) {
            listitems.push(
              <DrawerItem
                aria-label="Printing..."
                key="print"
                icon={<Print />}
                text={"menu.printing"}
                onClick={this.props.openPrinting}
              />
            );
          }
        }
        listitems.push(
          <DrawerItem
            aria-label="Downloads"
            key="downloads"
            icon={<Download />}
            text={"menu.download"}
            onClick={this.props.openDownloads}
          />
        );
        if (!archived) {
          listitems.push(
            <DrawerItem
              aria-label="Teams Data"
              key="teamsdata"
              icon={<Groups />}
              text={"menu.teamsdata"}
              onClick={this.props.openTeamsData}
            />
          );
          listitems.push(
            <DrawerItem
              aria-label="Speedings admin"
              key="speedingsadm"
              text={"menu.speedingsadmin"}
              icon={<Speed />}
              onClick={this.props.onSpeedingsAdmin}
            />
          );
          listitems.push(
            <DrawerItem
              aria-label="Speed areas"
              key="speedareas"
              icon={<Architecture />}
              text={"menu.speedareas"}
              onClick={this.props.openSpeedAreas}
            />
          );
          listitems.push(
            <DrawerItem
              aria-label="Series managment"
              key="series"
              icon={<EmojiEvents />}
              text={"menu.series"}
              onClick={this.props.openSeriesMgmt}
            />
          );

          /*
          listitems.push(
            <DrawerItem
              aria-label="Access codes"
              key="codes"
              icon={<FiberPin />}
              text={"menu.accesscodes"}
              onClick={this.props.openAccessCodes}
            />
          );
          */
        }
        this.props.joinrequiresconfirmation &&
          !archived &&
          listitems.push(
            <DrawerItem
              aria-label="Allow teams to event"
              key="teamsallow"
              icon={<LockOpen />}
              text={"menu.teamsallow"}
              onClick={this.props.openTeamsAllow}
            />
          );
        !archived &&
          listitems.push(
            <DrawerItem
              aria-label="Registered teams"
              key="regteams"
              icon={<HowToReg />}
              text={"menu.registered"}
              onClick={this.props.openRegistered}
            />
          );

        listitems.push(
          <DrawerItem
            aria-label="Marking statistics"
            key="marks"
            icon={<NotListedLocation />}
            text={"menu.markstatistics"}
            onClick={this.props.toggleShowMarks}
          />
        );
        listitems.push(
          <DrawerItem
            aria-label="Duplicate event"
            key="dupevent"
            icon={<FileCopy />}
            text={"menu.copyevent"}
            onClick={() => {
              this.setState({ duplicateEvent: true });
            }}
          />
        );
        !archived && listitems.push(<DeleteDrawerItem key="deleteev" />);
      }
      if (uid === argouid || isAdmin) {
        listitems.push(
          <DrawerItem
            aria-label="Access rights"
            key="access"
            icon={<VpnKeyRounded />}
            text={"menu.accessrights"}
            onClick={() => this.props.openDialog("accessRights")}
          />
        );
      }
    }
    if (uid) {
      // TODO Make this visible only for creators
      listitems.push(<ConditionalRestoreEvItem key="restoreev" />);
      listitems.push(
        <DrawerItem
          aria-label="News"
          key="news"
          icon={<Announcement />}
          text={"menu.announcment"}
          onClick={() => this.props.openDialog("news")}
        />
      );
    }

    listitems.push(<TranslatorToolMenuItem key="trans" />);
    if (uid === argouid) {
      listitems.push(
        <DrawerItem
          aria-label="Debugger tools"
          key="debugger"
          icon={<Engineering />}
          text={"menu.debuggertools"}
          onClick={() => this.props.openDialog("debuggerTools")}
        />
      );
      listitems.push(
        <DrawerItem
          aria-label="Upgrade tasks"
          key="upgradedb"
          icon={<SystemUpdateAlt />}
          text={"menu.upgradedb"}
          onClick={() => this.setState({ upgradeDialogOpen: true })}
        />
      );
      listitems.push(
        <DrawerItem
          aria-label="Events manager Old"
          key="eventsmanagerold"
          icon={<Engineering />}
          text={"Events manager Old"}
          onClick={() => this.props.openDialog("eventsManagerold")}
        />
      );
      listitems.push(
        <DrawerItem
          aria-label="Events manager"
          key="eventsmanager"
          icon={<Engineering />}
          text={"menu.eventsmanager"}
          onClick={() => this.props.openDialog("eventsManager")}
        />
      );
      listitems.push(
        <DrawerItem
          aria-label="Raamatupidamine"
          key="raamatupidamine"
          icon={<ReceiptLong />}
          text={"menu.accounting"}
          onClick={() => this.props.openDialog("accounting")}
        />
      );
    }
    if (this.state.upgradeDialogOpen) {
      listitems.push(
        <UpgradeDialog
          key="upgd"
          onClose={() => this.setState({ upgradeDialogOpen: false })}
        />
      );
    }
    if (this.state.openEventDialogOpen) {
      listitems.push(
        <OpenEventDialog
          key="oevd"
          onClose={() => {
            this.setState({ openEventDialogOpen: false });
          }}
        />
      );
    }
    if (this.state.resultDialogOpen && eventId) {
      listitems.push(
        <EventResultDialog
          key="resultdialog"
          eventid={eventId}
          handleClose={() => this.setState({ resultDialogOpen: false })}
        />
      );
    }
    return (
      <>
        <DrawerHeader closeDrawer={this.props.closeDrawer} />
        <List dense={true}>{listitems}</List>
        {this.state.duplicateEvent && eventId && (
          <Dialog open={true}>
            <CreateEvent
              duplicate={eventId}
              closeHandler={() => this.setState({ duplicateEvent: false })}
            />
          </Dialog>
        )}
      </>
    );
  }
}

export default connector(NutilogiDrawer);
