import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Drawer,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { setPlayTime } from "rx/appStateSlice";
import dayjs from "dayjs";
import { isPointInArea } from "rx/simpleFirstoreDocSlice";
import { amber, red } from "@mui/material/colors";
import { TrackPoint } from "rx/tracksSlice";
import { SpdTickets } from "rx/fbListSlices";
import { DrawerHeaderWithStyles } from "./DrawerHeader";

const StyledDrawer = styled("div")({
  height: "100%",
  "& .over1": {
    color: amber[600],
    fontWeight: "bolder",
  },
  "& .over2": {
    color: amber[800],
    fontWeight: "bolder",
  },
  "& .ticket": {
    color: red[700],
  },
});
const mapState = (state: RootState) => ({
  playTime: state.appState.playTime,
  speedAreas: state.eventDoc?.speedareas || {},
  speedlimit: state.eventDoc?.speed?.limit,
  over1: Number(state.eventDoc?.speed?.over1),
  over2: Number(state.eventDoc?.speed?.over2),
});
const mapDispatch = {
  setPlayTime: (t: number) => setPlayTime(t),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

enum SecondRowType {
  SpeedArea,
  SpeedLimit,
  Accuracy,
  MissingPoints,
  Timestamp,
  Location,
  OverCount,
}
type TrackDataDrawerOwnProps = {
  track: TrackPoint[];
  tickets?: SpdTickets;
  ticketIndex?: number;
  currentIndex?: number;
};
type TrackDataDrawerProps = PropsFromRedux &
  WithTranslation &
  TrackDataDrawerOwnProps;
type TrackDataDrawerState = {
  secondrowtype: SecondRowType;
};
class TrackDataDrawer extends React.Component<
  TrackDataDrawerProps,
  TrackDataDrawerState
> {
  state: TrackDataDrawerState = {
    secondrowtype: SecondRowType.SpeedLimit,
  };

  listRef?: FixedSizeList;

  componentDidUpdate(prevProps: TrackDataDrawerProps) {
    if (this.props.currentIndex !== prevProps.currentIndex) {
      if (this.props.currentIndex) {
        this.listRef?.scrollToItem(this.props.currentIndex, "center");
      }
    }
    if (this.props.ticketIndex !== prevProps.ticketIndex) {
      if (this.props.ticketIndex) {
        this.listRef?.scrollToItem(this.props.ticketIndex, "center");
      }
    }

    //this.listRef.current?.scrollToItem()
  }

  shouldComponentUpdate(nextProps: TrackDataDrawerProps) {
    return true;
    /*
    if (nextProps.ticket) {
      if (!nextProps.ticket.ticketIndex) {
        nextProps.ticket.ticketIndex = nextProps.locationData.findIndex(
          (i) => i.t >= nextProps.ticket.t
        );
      }
      if (nextProps.ticket !== this.props.ticket) {
        this.setState({ scrollToIndex: nextProps.ticket.ticketIndex });
      }
    }

    if (
      nextProps.replayTime !== this.props.replayTime &&
      nextProps.locationData
    ) {
      let idx = nextProps.locationData.findIndex(
        (i) => i.t >= nextProps.replayTime
      );
      this.setState({ scrollToIndex: idx });
    }
    return true;
    */
  }

  renderTrackItem = ({ index, style }: ListChildComponentProps) => {
    let timestr = dayjs(this.props.track[index].t).format("HH:mm:ss");

    let area = Object.values(this.props.speedAreas).find((s) =>
      isPointInArea(this.props.track[index], s)
    );
    const limit =
      area && area.limit ? area.limit : Number(this.props.speedlimit);

    let secondstr = "";

    if (this.state.secondrowtype === SecondRowType.SpeedArea) {
      if (area !== undefined) {
        let name =
          area.name.length > 7 ? area.name.substr(0, 7) + "..." : area.name;
        if (area.restricted) secondstr = name;
        else secondstr = name + " (" + area.limit + " km/h)";
      }
    } else if (this.state.secondrowtype === SecondRowType.Accuracy) {
      secondstr = this.props.track[index].a.toFixed(2);
    } else if (this.state.secondrowtype === SecondRowType.MissingPoints) {
      if (index > 0) {
        const timedst =
          this.props.track[index].t - this.props.track[index - 1].t;
        if (timedst > 1000) secondstr = (timedst / 1000).toFixed(0);
      }
    } else if (this.state.secondrowtype === SecondRowType.Timestamp) {
      secondstr = this.props.track[index].t.toString();
    } else if (this.state.secondrowtype === SecondRowType.Location) {
      secondstr =
        this.props.track[index].lat.toFixed(4) +
        ", " +
        this.props.track[index].lng.toFixed(4);
    } else if (this.state.secondrowtype === SecondRowType.SpeedLimit) {
      if (limit) secondstr = limit + this.props.over1 + " km/h";
      if (limit && this.props.over2)
        secondstr += " / " + (limit + this.props.over2) + " km/h";
    } else if (this.state.secondrowtype === SecondRowType.OverCount) {
    }
    let cl = undefined;

    if (
      this.props.tickets &&
      this.props.tickets[this.props.track[index].t] !== undefined
    )
      cl = "ticket";
    else if (limit && this.props.track[index].s > limit + this.props.over2)
      cl = "over2";
    else if (limit && this.props.track[index].s > limit + this.props.over1)
      cl = "over1";

    return (
      <ListItemButton
        selected={this.props.currentIndex === index}
        onClick={() => {
          this.props.setPlayTime(this.props.track[index].t);
        }}
        className={cl}
        style={style}
      >
        <ListItemText
          primary={
            timestr + " - " + Math.floor(this.props.track[index].s) + " km/h"
          }
          secondary={secondstr}
        />
      </ListItemButton>
    );
  };

  render() {
    const { track, t } = this.props;

    if (!track) return null;

    return (
      <Drawer anchor="right" variant="persistent" open={true}>
        <DrawerHeaderWithStyles />
        <Select
          label={t("speeddata.secondlabel")}
          value={this.state.secondrowtype}
          onChange={(event) => {
            this.listRef?.forceUpdate();
            this.setState({ secondrowtype: Number(event.target.value) });
          }}
        >
          {Object.keys(SecondRowType)
            .filter((k) => typeof SecondRowType[k as any] !== "number")
            .map((k) => (
              <MenuItem key={k} value={k}>
                {SecondRowType[k as any]}
              </MenuItem>
            ))}
        </Select>
        <StyledDrawer>
          <AutoSizer>
            {({ width, height }: Size) => (
              <FixedSizeList
                width={width || 0}
                height={height || 0}
                itemSize={55}
                itemCount={track.length}
                ref={(listref) => {
                  if (!listref) {
                    this.listRef = undefined;
                    return;
                  }
                  if (this.props.ticketIndex)
                    listref?.scrollToItem(this.props.ticketIndex, "center");
                  this.listRef = listref;
                }}
              >
                {this.renderTrackItem}
              </FixedSizeList>
            )}
          </AutoSizer>
        </StyledDrawer>
      </Drawer>
    );
  }
}

export default connector(withTranslation()(TrackDataDrawer));
