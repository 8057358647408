import {
  createSlice,
  Slice,
  CaseReducer,
  PayloadAction,
} from "@reduxjs/toolkit";
import { LatLngLiteral } from "leaflet";
import { eventIdSlice } from "./eventIdSlice";

type FBCollectionSliceActions<T> = {
  replaceValue: CaseReducer<T, { payload: { [key: string]: T }; type: string }>;
  combineValue: CaseReducer<T, { payload: { [key: string]: T }; type: string }>;
  onChildAdded: CaseReducer<T, { payload: { [key: string]: T }; type: string }>;
  onChildChanged: CaseReducer<
    T,
    { payload: { [key: string]: T }; type: string }
  >;
  onChildRemoved: CaseReducer<T, { payload: string; type: string }>;
  clearState: CaseReducer;
  clearKeys: CaseReducer<T, { payload: string[]; type: string }>;
};

enum FirebaseGroup {
  EventsData = "eventsdata",
  TeamsData = "teams",
}

export type FBSlice<T> = {
  autoListen: boolean;
  group: FirebaseGroup;
  autoReducer: boolean;
  replaceOnValue: boolean;
  path: string;
  initialComplete: boolean;
} & Slice<T, FBCollectionSliceActions<T>, string>;

//export declare function createSlice<State, CaseReducers extends SliceCaseReducers<State>, Name extends string = string>(options: CreateSliceOptions<State, CaseReducers, Name>): Slice<State, CaseReducers, Name>;

/* Test if we can have single object of this */
const fbreducer: FBCollectionSliceActions<any> = {
  combineValue: (state, action) => {
    Object.assign(state, action.payload);
  },
  replaceValue: (_, action) => {
    if (action.payload === null) return {};
    return action.payload;
  },
  onChildAdded: (state, action) => {
    Object.assign(state, action.payload);
  },
  onChildChanged: (state, action) => {
    Object.assign(state, action.payload);
  },
  onChildRemoved: (state: { [key: string]: any }, action) => {
    delete state[action.payload];
  },
  clearState: (_state, _action) => {
    return {};
  },
  clearKeys: (state, action) => {
    action.payload.forEach((k) => delete state[k]);
  },
};
interface fbSliceOptions {
  autoListen?: boolean;
  autoReducer?: boolean; // TODO probably not used
  replaceOnValue?: boolean;
  needAdmin?: boolean;
  freeAfterEventEnd?: boolean;
}
function fbCollectionSlice<T>(
  name: string,
  initialState: { [key: string]: T },
  group: FirebaseGroup,
  path: string,
  {
    autoListen = true,
    autoReducer = true,
    replaceOnValue = true,
    needAdmin = false,
    freeAfterEventEnd = false,
  }: fbSliceOptions = {}
) {
  return Object.assign(
    {
      autoListen: autoListen,
      autoReducer: autoReducer,
      replaceOnValue: replaceOnValue,
      group: group,
      path: path,
      needAdmin: needAdmin,
      freeAfterEventEnd: freeAfterEventEnd,
      initialComplete: false,
    },
    createSlice({
      name: name,
      initialState,
      reducers: fbreducer,
      extraReducers: (builder) => {
        builder.addCase(eventIdSlice.actions.setEventId, (state, action) => {
          return {};
        });
      },
    })
  );
}

export interface KPdata {
  desc?: string;
  longdesc?: string;
  a?: LatLngLiteral;
  loc?: LatLngLiteral;
  anycross?: boolean;
  responses?: {
    [rid: string]: string;
  };
}

export interface KP {
  nr: string | number;
  allok?: boolean;
  isauto?: boolean;
  wrongpenalty?: number;
  ra?: number;
  grp?: number;
  maxanswerdistance?: number;
  tyhistatud?: boolean;
  inwork?: boolean;
  kohustuslik?: boolean;
  noopenwhennotclose?: boolean;
  dependkp?: string;
}

export type TeamList = {
  name?: string;
  nr?: string;
  disabled?: boolean;
  starttime?: number;
  finishtime?: number;
  col?: string;
  klassid: {
    [key: string]: string | boolean;
  };
  devs: {
    [devid: string]: string;
  };
  defaultdev?: string;
  allowed?: boolean;
  dsq?: boolean;
  katk?: boolean;
};

export type TeamData = {
  devs: {
    [devid: string]: {
      api?: number;
      appversioncode?: number;
      appVersion?: string;
      board?: string;
      bootloader?: string;
      brand?: string;
      device?: string;
      display?: string;
      displayMetrics?: {
        heightPx: number;
        widthPx: number;
        xDpi: number;
        yDpi: number;
      };
      hardware?: string;
      host?: string;
      id?: string;
      isPhysicalDevice?: boolean;
      model?: string;
      name?: string;
      pkginfo?: {
        appName: string;
        appVersion: string;
        buildNumber: string;
        packageName: string;
      };
      platform?: string;
      product?: string;
      fingerprint?: string;
      manufacturer?: string;
      systemName?: string;
      version?: {
        baseOS: string;
        codename: string;
        incremental: string;
        previewSdkInt: number;
        release: string;
        sdkInt: number;
        securityPatch: string;
      };
    };
  };
  displayName?: string;
  eolcode?: string;
  providerData?: string;
  providerId?: string;
  email?: string;
  emailVerified?: boolean | string; // TODO. Check why it's getting string
  phoneNumber?: string;
  photoURL?: string;
  providerid?: string;
  regdata?: {
    [k: string]: string;
  };
};

type EventConf = {
  wheelofnames?: {
    events?: {
      [evid: string]: true;
    };
    ignnames?: string[];
    id?: string;
  };
};

export type AnswerResult = {
  [kpid: string]: {
    ok?: boolean;
  };
};

type TeamAnswers = {
  [kpid: string]: {
    aeg?: number;
    answer?: string;
    ok?: boolean;
  };
};

export type SpdTicket = {
  limit?: number;
  spd?: number;
  type?: string;
  area?: string;
  state?: string;
};
export type SpdTickets = {
  [tstamp: string]: SpdTicket;
};

export type TeamSpdTickets = {
  [devid: string]: SpdTickets;
};

export type Marks = {
  [devid: string]: {
    [markid: string]: {
      aeg: number;
      answer: string;
      kpid: string;
      lat: number;
      lng: number;
      reject?: boolean;
    };
  };
};

export type KPAnswers = string;

export const fbslices = {
  kpList: fbCollectionSlice<KP>("kp", {}, FirebaseGroup.EventsData, "kp"),
  kpData: fbCollectionSlice<KPdata>(
    "kpdata",
    {},
    FirebaseGroup.EventsData,
    "kpdata"
  ),
  kpAnswers: fbCollectionSlice<KPAnswers>(
    "kpanswers",
    {},
    FirebaseGroup.EventsData,
    "kpanswer",
    {
      needAdmin: true,
      freeAfterEventEnd: true,
    }
  ),
  teamsList: fbCollectionSlice<TeamList>(
    "teamsList",
    {},
    FirebaseGroup.TeamsData,
    "list"
  ),
  teamsData: fbCollectionSlice<TeamData>(
    "teamsData",
    {},
    FirebaseGroup.TeamsData,
    "data",
    {
      needAdmin: true,
    }
  ),
  answerResult: fbCollectionSlice<AnswerResult>(
    "answerresult",
    {},
    FirebaseGroup.TeamsData,
    "answerresult"
  ),
  teamAnswers: fbCollectionSlice<TeamAnswers>(
    "teamanswers",
    {},
    FirebaseGroup.TeamsData,
    "kpanswers",
    {
      needAdmin: true,
      freeAfterEventEnd: true,
    }
  ),
  spdTickets: fbCollectionSlice<TeamSpdTickets>(
    "spdtickets",
    {},
    FirebaseGroup.TeamsData,
    "spdtickets"
  ),
  marks: fbCollectionSlice<Marks>(
    "marklog",
    {},
    FirebaseGroup.TeamsData,
    "marklog",
    { needAdmin: true }
  ),
  eventConf: fbSlice<EventConf>(
    "eventConf",
    {},
    FirebaseGroup.EventsData,
    "conf",
    {
      autoListen: false,
      freeAfterEventEnd: false,
    }
  ),
};

export const fbReducerMap = {
  kpList: fbslices.kpList.reducer,
  kpData: fbslices.kpData.reducer,
  kpAnswers: fbslices.kpAnswers.reducer,
  teamsList: fbslices.teamsList.reducer,
  teamsData: fbslices.teamsData.reducer,
  answerResult: fbslices.answerResult.reducer,
  teamAnswers: fbslices.teamAnswers.reducer,
  spdTickets: fbslices.spdTickets.reducer,
  marks: fbslices.marks.reducer,
  eventConf: fbslices.eventConf.reducer,
};

export type DataAvailabilityType = {
  [k in keyof typeof fbReducerMap | "events" | "eventData"]?: boolean;
};

export const dataAvailabilitySlice = createSlice({
  name: "dataAvailability",
  initialState: {} as DataAvailabilityType,
  reducers: {
    setDataAvailable: (
      state,
      action: PayloadAction<keyof DataAvailabilityType>
    ) => {
      state[action.payload] = true;
    },
    requestData: (state, action: PayloadAction<keyof DataAvailabilityType>) => {
      if (state[action.payload] === undefined) state[action.payload] = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(eventIdSlice.actions.setEventId, (state, action) => {
      Object.keys(state).forEach((k) => ((state as any)[k] = false));
    });
  },
});

function fbSlice<T extends object>(
  name: string,
  initialState: T,
  group: FirebaseGroup,
  path: string,
  {
    autoListen = true,
    autoReducer = true,
    replaceOnValue = true,
    needAdmin = true,
    freeAfterEventEnd = true,
  }: fbSliceOptions = {}
) {
  return Object.assign(
    {
      autoListen: autoListen,
      autoReducer: autoReducer,
      replaceOnValue: replaceOnValue,
      needAdmin: needAdmin,
      freeAfterEventEnd: freeAfterEventEnd,
      group: group,
      path: path,
      initialComplete: false,
    },
    createSlice({
      name: name,
      initialState,
      reducers: fbreducer,
      extraReducers: (builder) => {
        builder.addCase(eventIdSlice.actions.setEventId, (state, action) => {
          return {} as T;
        });
      },
    })
  );
}
export type EventType = {
  name?: string;
  starttime?: number;
  endtime?: number;
  arch?: string;
  hidden?: boolean;
  admins?: {
    [uid: string]: string;
  };
};
export type EventDataType = {
  kpsinweb?: boolean;
  type?: "paevak" | "valik" | "race";
  resulttype?: "roadbook";
  joinstartinterval?: number;
  clientstart?: boolean;
  massstarttimems?: number;
  startloc?: LatLngLiteral;
  maxmarkingdistance?: number;
  maxstartdistance?: number;
  firstkpvisitbonus?: number;
  firstkpvisitaddbonus?: number;
  orderrules?: string;
  sfwithseconds?: boolean;
  sfwithdate?: boolean;
  hidespeedings?: boolean;
  joinrequiresconfirmation?: boolean;
  appmessage?: string;
  prepagemessage?: string;
  notallowedmessage?: string;
  finishedmessage?: string;
  nokpopenwhennotclose?: boolean;
  firstbonusismultiplier?: boolean;
  distanceinresult?: boolean;
  hidewrongmarks?: boolean;
  valiktahed?: string;
  dstresult?: {
    targetdst?: number;
    dststeps?: number;
    dstpoints?: number;
    dstpointssteps?: number;
  };
  firstbonus?: [bonus: number];
  eventbounds?: {
    northEast: LatLngLiteral;
    southWest: LatLngLiteral;
  };
  speedranges?: [
    {
      s: number;
      c: string;
    },
  ];
  klassid?: {
    [key: string]: string | boolean; // TODO Kui on uus portal käivitatud, siis muuta kõigil võistlustel klass uue variandi peale ja eemaldada siit boolean
  };
  ly?: {
    [lykey: string]: {
      name: string;
      ispenalty?: boolean;
      forclasses?: string[];
    };
  };
  ajad?: {
    lisaajad?: {
      penalty: number;
      per?: number;
      time?: number;
    }[];
    normaalaeg?: number;
  };
  hideresults?: boolean;
  answerscores?: boolean;
  hidetracks?: boolean;
  wrongpenaltyenabled?: boolean;
  wrongcoef?: number;
  sharedrakoef?: string;
  pointssuffix?: string;
  map?: {
    default?: {
      type?: string;
      conf?: string;
    };
    userlayers?: { type: string; title: string; conf: string }[];
  };
  kpgroups?:
    | {
        [key: number]: number;
      }
    | (number | null)[];
  rakoef?:
    | {
        [key: number]: number;
      }
    | (number | null)[];
};
type EventState = EventType & EventDataType;

export const eventSlice = fbSlice<EventState>(
  "event",
  {},
  FirebaseGroup.EventsData,
  "data",
  { autoReducer: false, replaceOnValue: false }
);

export default fbslices;
