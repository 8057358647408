import { Component, useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import EventResult from "./EventResult";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckIcon from "@mui/icons-material/Check";
import NoSleep from "nosleep.js";
import { Typography } from "@mui/material";
import store, { useTSelector } from "rx/store";
import { useTranslation } from "react-i18next";
import ResponsesResultTable from "components/ResponsesResultTable";
import {useEventEnded } from "../utils/SmallUtils";


const VastusteButton = (eventid) => {
  const enabled = useTSelector(state => state.event.questionsresult);
  const eventAccess = useTSelector(state => state.user.eventAccess);
  const eventEnded = useEventEnded();

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  if (!(enabled && (eventEnded || eventAccess)) ) return null;
  return<>
    <Button onClick={() => setOpen(!open)}>{t("button.responsesresult")}</Button>
    <Dialog open={open} fullScreen>
      <DialogTitle>{t("title.responsesresult")}</DialogTitle>
      <DialogContent>
        <ResponsesResultTable eventId={eventid}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{t("button.close")}</Button>
      </DialogActions>
    </Dialog>
    </>
}
class EventResultDialog extends Component {
  state = {
    anchorEl: null,
    fullScreen: false,
    hideSettingsAndClose: false,
    showQR: false,
  };

  componentDidMount() {
    this.noSleep = new NoSleep();
    this.noSleep.enable();
  }

  componentWillUnmount() {
    this.noSleep.disable();
  }

  openSettingsMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  render() {
    const { eventid } = this.props;

    const eventname = store.getState().event.name; // Currently hack. Should rewrite resultdialog in typescript

    return (
      <Dialog
        fullScreen={this.state.fullScreen}
        open={true}
        maxWidth={false}
        onClose={() => this.props.handleClose()}
        aria-labelledby="event-result-title"
      >
        <DialogTitle id="event-result-title">
          <Box  sx={{ display: "flex" }} alignItems="baseline ">
            
          <Typography variant="h6" noWrap >
            {eventname}
          </Typography>
          {!this.state.hideSettingsAndClose && (
              <IconButton
              aria-label="More"
              aria-owns={this.state.anchorEl === null ? "long-menu" : null}
              aria-haspopup="true"
              onClick={this.openSettingsMenu}
              size="large">
              <MoreVertIcon />
            </IconButton>
            )}
            <Box sx={{ flexGrow: 1}}/>
            <VastusteButton/>
            </Box>
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.anchorEl !== null}
            onClose={() => {
              this.setState({ anchorEl: null });
            }}
            PaperProps={{
              style: {
                width: 200,
              },
            }}
          >
            <MenuItem
              onClick={() =>
                this.setState({
                  fullScreen: !this.state.fullScreen,
                  anchorEl: null,
                })
              }
            >
              {this.state.fullScreen && (
                <ListItemIcon >
                  <CheckIcon />
                </ListItemIcon>
              )}
              Full Screen
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.setState({ showQR: !this.state.showQR, anchorEl: null })
              }
            >
              {this.state.showQR && (
                <ListItemIcon >
                  <CheckIcon />
                </ListItemIcon>
              )}
              Show QR to result
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.setState({ hideSettingsAndClose: true, anchorEl: null })
              }
            >
              {this.state.timePickerWithSeconds && (
                <ListItemIcon >
                  <CheckIcon />
                </ListItemIcon>
              )}
              Hide settings and close
            </MenuItem>
          </Menu>
        </DialogTitle>
        <DialogContent>
          <EventResult />
        </DialogContent>
        <DialogActions>
          {!this.state.hideSettingsAndClose && (
            <Button onClick={() => this.props.handleClose()} color="primary">
              Close
            </Button>
          )}
        </DialogActions>
        {this.state.showQR && (
          <div
            style={{
              position: "fixed",
              bottom: "30px",
              left: "30px",
              zIndex: "99",
            }}
          >
            <QRCodeSVG value={"http://nutilogi.web.app/" + eventid + "/result"} />
          </div>
        )}
      </Dialog>
    );
  }
}

EventResultDialog.propTypes = {
  eventid: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
};

export default connect()(EventResultDialog);
