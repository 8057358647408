import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridColumnMenu,
  GridColumnMenuItemProps,
  GridColumnMenuProps,
  GridEditInputCell,
  GridEditSingleSelectCell,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  darken,
  lighten,
  styled,
} from "@mui/material";
import {
  AlarmOn,
  Cancel,
  CancelOutlined,
  CheckCircleOutline,
  Done,
} from "@mui/icons-material";
import { useTSelector } from "rx/store";
import { ReactElement, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { setEditKPId } from "rx/appStateSlice";
import { useNL } from "utils/NLContext";
import { argouid } from "utils/SmallUtils";
import { child, getDatabase, ref } from "firebase/database";
import { useTranslation } from "react-i18next";
import { TeamResultWithSummaries } from "utils/ValikResultCalcNextGen";

/*
TODOS
 + muudatuste salvestamine processRowUpdate-is
 + KP filter to open menu
 + KP filter menu actions
 + Actions to mark it to correct or delete 
 + Add action to set current time to answer time. Icon = AlarmOn
 + Test kuidas tootab allok nupuga oigeks markimine.
 + AllOk voiks naidata checked marki kui on oige.
 + auto size columns yle vaadata
 + KP vaartuse tulp.
 + naita aega stardist paevades kui on suurem kui 24 h
 - add tooltips to actions
 - add link syncronization to edit team
 - check that edit team without access wont reveal anything important
 - kui on tahis, siis kp edit ei toota oigesti.
*/

interface CustomToolbarProps extends GridToolbarProps {
  teamname?: string;
  currentFilter: KPFilterEnum;
  setKPFilter: (f: KPFilterEnum) => void;
}

enum KPFilterEnum {
  AllKPs,
  OnlyMarked,
  OnlyUnmarked,
  OnlyWrong,
}

const KPFilterMenuItems = [
  { id: KPFilterEnum.AllKPs, title: "kp.filter.allkps" },
  { id: KPFilterEnum.OnlyMarked, title: "kp.filter.onlymarked" },
  { id: KPFilterEnum.OnlyUnmarked, title: "kp.filter.onlyunmarked" },
  { id: KPFilterEnum.OnlyWrong, title: "kp.filter.onlywrong" },
];

function KPTableToolbar(props: any) {
  // props could be GridToolbarProps & somethihng
  //console.log('tb props', props)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const handleKPFilterMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleKPFilterMenuClose = (f: KPFilterEnum) => () => {
    setAnchorEl(null);
    props.setKPFilter(f);
  };
  const open = Boolean(anchorEl);

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: props.teamname,
          utf8WithBom: true,
        }}
      />
      <Button onClick={handleKPFilterMenuOpen}>KP Filter</Button>
      <Box sx={{ flex: 1 }} />
      <GridToolbarQuickFilter />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        variant="selectedMenu"
      >
        {KPFilterMenuItems.map((e) => (
          <MenuItem
            key={e.id}
            selected={props.currentFilter === e.id}
            onClick={handleKPFilterMenuClose(e.id)}
          >
            {props.currentFilter === e.id && (
              <ListItemIcon>
                <Done />
              </ListItemIcon>
            )}
            <ListItemText inset={props.currentFilter !== e.id}>
              {t(e.title as any)}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </GridToolbarContainer>
  );
}

function CustomUserItem(props: GridColumnMenuItemProps) {
  const { myCustomHandler, myCustomValue } = props;
  return (
    <MenuItem onClick={myCustomHandler}>
      <ListItemIcon>
        <Cancel fontSize="small" />
      </ListItemIcon>
      <ListItemText>{myCustomValue}</ListItemText>
    </MenuItem>
  );
}

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Add new item
        columnMenuUserItem: CustomUserItem,
      }}
      slotProps={{
        columnMenuUserItem: {
          // set `displayOrder` for the new item
          displayOrder: 15,
          // Additional props
          myCustomValue: "Do custom action",
          myCustomHandler: () => alert("Custom handler fired"),
        },
      }}
    />
  );
};

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .kpok": {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
    },
  },
  "& .kpwrong": {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
    },
  },
  "& .kptyhistatud": {
    backgroundColor: getBackgroundColor(
      theme.palette.info.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
    },
  },
}));

const getTogglableColumns = (columns: (GridColDef & { hide?: boolean })[]) => {
  return columns.filter((column) => !column.hide).map((column) => column.field);
};

type ResultKPs = TeamResultWithSummaries["kps"];
const TeamKPTable: React.FC<{ tid: string; kps: ResultKPs }> = ({
  tid,
  kps,
}) => {
  const teamname = useTSelector((s) => s.teamsList[tid]?.name);
  const eventId = useTSelector((s) => s.eventId) ?? "-";
  const uid = useTSelector((s) => s.user.user?.uid);
  const [kpfilter, setKPFilter] = useState<KPFilterEnum>(KPFilterEnum.AllKPs);
  const dispatch = useDispatch();
  const { fbSet, fbRemove } = useNL();
  const { t } = useTranslation();

  const rows = useMemo(
    () =>
      kps
        .filter((e) => e.kp.nr !== "F")
        .filter((kp) => {
          switch (kpfilter) {
            case KPFilterEnum.AllKPs:
              return true;
            case KPFilterEnum.OnlyMarked:
              return kp.marked;
            case KPFilterEnum.OnlyUnmarked:
              return !kp.marked;
            default:
              return kp.marked && !kp.correct;
          }
        }),
    [kps, kpfilter]
  );

  const kpanswersref = useMemo(() => {
    return ref(getDatabase(), `/teams/${eventId}/kpanswers/${tid}`);
  }, [eventId, tid]);
  //type KPGridColDef = GridColDef<ResultKPs[number]> & { hide?: boolean };
  type KPGridColDef = GridColDef & { hide?: boolean }; // When using StyledDataGrid, this one has to be used
  const columns: KPGridColDef[] = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "nr",
      headerName: "Nr",
      renderCell: (params) => (
        <Button onClick={() => dispatch(setEditKPId(params.row.id))}>
          {params.row.kp.nr}
        </Button>
      ),
    },
    {
      field: "desc",
      headerName: t("kpdata.header.desc"),
      valueGetter: (value, row) => row.kpdata?.desc,
    },
    {
      field: "longdesc",
      headerName: t("kpdata.header.longdesc"),
      valueGetter: (value, row) => row.kpdata?.longdesc,
    },
    {
      field: "tanswer",
      headerName: t("kpdata.header.response"),
      valueGetter: (value, row) => row.response,
      renderEditCell(params) {
        if (params.row.kpdata?.responses)
          return <GridEditSingleSelectCell {...params} />;
        else return <GridEditInputCell {...params} />;
      },
      renderCell: (params) => {
        if (!params.row.kp.allok) return params.value;
        if (params.row.correct) {
          return <Done sx={{ verticalAlign: "middle" }} />;
        }
        return params.value;
      },
      type: "singleSelect",
      editable: true,

      valueOptions: (params) =>
        Object.values(params.row?.kpdata?.responses ?? {}),
      valueSetter: (value, row) => {
        console.log("in valuesetter", value, row);
        const resp = Object.assign({}, row);
        const responses = resp?.kpdata?.responses;
        if (responses) {
          const rentry = Object.entries(responses).find(
            ([rid, r]) => r === value
          );
          if (rentry) resp.response = rentry[0];
        } else {
          resp.response = value;
        }
        return resp;
      },
    },
    {
      field: "taeg",
      headerName: t("header.marktime"),
      editable: true,
      type: "string",
      valueGetter: (value, row) => {
        if (row.aeg === undefined) return "";
        return dayjs(row.aeg).format("HH:mm:ss");
      },
      renderCell: (params) => (
        <Tooltip title={dayjs(params.row.aeg).format("lll")}>
          <span>{params.value}</span>
        </Tooltip>
      ),

      valueSetter: (value, row) => {
        const resp = Object.assign({}, row);

        if (value === "") {
          resp.aeg = undefined;
          return resp;
        }
        console.log("row taeg", row.aeg);
        const prevtime = dayjs(row.aeg).format("YYYY-MM-DD ");
        let newtime = dayjs(prevtime + value);
        if (!newtime.isValid()) {
          newtime = dayjs(value);
        }

        if (!newtime.isValid) return resp;
        //console.log(dayjs(row.taeg).toISOString(), newtime.toISOString())
        resp.aeg = newtime.valueOf();

        return resp;
      },
    },
    {
      field: "canswer",
      headerName: t("header.correctanswer"),
      valueGetter: (_, row) => row.correctanswer,
    },
    {
      field: "value",
      headerName: t("header.value"),
      valueGetter: (_, row) => row.value,
    },
    {
      field: "bonuses",
      headerName: t("header.bonuses"),
      valueGetter: (_, row) => row.bonus,
    },
    {
      field: "penalties",
      headerName: t("header.penalties"),
      valueGetter: (_, row) => row.penalty,
    },
    {
      field: "points",
      headerName: t("header.points"),
      valueGetter: (_, row) => row.points,
    },
    {
      field: "db",
      hide: uid !== argouid,
      renderCell: (params: any) => (
        <Button
          onClick={() =>
            window.open(child(kpanswersref, params.row.id).toString(), "_blank")
          }
        >
          Open
        </Button>
      ),
    },
    {
      field: "actions",
      headerName: t("header.actions"),
      type: "actions",
      getActions(params) {
        let actions: ReactElement[] = [];
        if (!params.row.kp.tyhistatud && !params.row.correct) {
          actions.push(
            <Tooltip title={t("kp.answer.added.tooltip")}>
              <GridActionsCellItem
                icon={<CheckCircleOutline />}
                label="Fix answer"
                onClick={() => {
                  if (params.row.kp.allok) {
                    fbSet(
                      child(kpanswersref, params.row.id + "/aeg"),
                      dayjs().valueOf(),
                      t("kp.answer.addtime.undo")
                    );
                  } else if (params.row.kpanswer) {
                    fbSet(
                      child(kpanswersref, params.row.id + "/answer"),
                      params.row.kpanswer,
                      t("kp.answer.added.undo")
                    );
                  }
                }}
              />
            </Tooltip>
          );
        }
        if (params.row.marked && !params.row.aeg) {
          actions.push(
            <Tooltip title={t("kp.answer.addtime.tooltip")}>
              <GridActionsCellItem
                icon={<AlarmOn />}
                label="Add current time to answer"
                onClick={() =>
                  fbSet(
                    child(kpanswersref, params.row.id + "/aeg"),
                    dayjs().valueOf(),
                    t("kp.answer.addtime.undo")
                  )
                }
              />
            </Tooltip>
          );
        }
        if (params.row.aeg || params.row.marked) {
          actions.push(
            <Tooltip title={t("kp.answer.removed.tooltip")}>
              <GridActionsCellItem
                icon={<CancelOutlined />}
                label="Delete answer"
                onClick={() =>
                  fbRemove(
                    child(kpanswersref, params.row.id),
                    t("kp.answer.removed.undo")
                  )
                }
              />
            </Tooltip>
          );
        }
        return actions;
      },
    },
  ];

  const toolbarProps: CustomToolbarProps = {
    showQuickFilter: true,
    teamname: teamname,
    currentFilter: kpfilter,
    setKPFilter: (f: KPFilterEnum) => {
      setKPFilter(f);
    },
  };
  return (
    <Box sx={{ height: 400, width: "100%", paddingTop: 2 }}>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
          sorting: {
            sortModel: [{ field: "nr", sort: "asc" }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              db: false,
              value: false,
              bonuses: false,
              penalties: false,
            },
          },
        }}
        pageSizeOptions={[5, 10, 50, 100]}
        disableRowSelectionOnClick
        autosizeOnMount
        autosizeOptions={{
          columns: ["id", "nr", "desc", "tanswer", "taeg"],
          includeOutliers: true,
          includeHeaders: true,
        }}
        slots={{
          toolbar: KPTableToolbar,
          columnMenu: CustomColumnMenu,
        }}
        slotProps={{
          toolbar: toolbarProps,
          columnsManagement: {
            getTogglableColumns,
          },
        }}
        isCellEditable={(params) =>
          !(params.field === "tanswer" && params.row.kp.allok)
        }
        getRowClassName={(params) => {
          if (params.row.kp.tyhistatud) return "kptyhistatud";
          if (params.row.correct) return "kpok";
          if (params.row.marked) return "kpwrong";
          return "";
        }}
        processRowUpdate={(updatedRow, originalRow) => {
          console.log(originalRow, updatedRow);

          if (originalRow.response !== updatedRow.response) {
            fbSet(
              child(kpanswersref, originalRow.id + "/answer"),
              updatedRow.response,
              t("kp.answer.updated")
            );
          }
          if (originalRow.aeg !== updatedRow.aeg) {
            if (updatedRow.aeg !== undefined && isNaN(updatedRow.aeg))
              return originalRow;
            if (updatedRow.aeg === undefined) {
              fbRemove(
                child(kpanswersref, originalRow.id + "/aeg"),
                t("kp.answer.removedtime")
              );
            } else {
              fbSet(
                child(kpanswersref, originalRow.id + "/aeg"),
                updatedRow.aeg,
                t("kp.answer.timeupdated")
              );
            }
          }

          return updatedRow;
        }}
      />
    </Box>
  );
};

export default TeamKPTable;
