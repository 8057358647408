import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import * as L from "leaflet";
import "leaflet-draw";
import SpeedArea from "./SpeedArea";
import { execWithProgress } from "rx/saveInProgressSlice";
import { isMobile } from "react-device-detect";
import { AreaObjectType } from "rx/simpleFirstoreDocSlice";
import { RemoveCircleRounded } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import {
  deleteField,
  DocumentReference,
  getFirestore,
  doc,
  updateDoc,
  GeoPoint,
  getDoc,
  setDoc,
} from "@firebase/firestore/lite";
import { getAuth } from "firebase/auth";
import NLTooltip from "./NLTooltip";

const mapState = (state: RootState) => ({
  eventId: state.eventId,
  eventAreas: state.eventDoc?.speedareas || {},
  isRoadBookEvent: state.event.resulttype === "roadbook",
});
const mapDispatch = {
  execWithProgress: (f: () => Promise<any>) => execWithProgress(f),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type SpeedAreasEditorProps = PropsFromRedux & WithTranslation & {};
type SpeedAreasEditorState = {
  limit?: number;
  addNewArea: boolean;
  editAreaId?: string;
  numPointsInArea: number;
  areaPoints?: L.LatLng[];
  areaName: string;
  areaRestricted: boolean;
  timedArea: boolean;
  areaLimit: string;
  confirmDiscard: boolean;
  newEditAreaId?: string;
};
class SpeedAreasEditor extends React.Component<
  SpeedAreasEditorProps,
  SpeedAreasEditorState
> {
  state: SpeedAreasEditorState = {
    addNewArea: false,
    numPointsInArea: 0,
    areaName: "",
    areaRestricted: false,
    timedArea: false,
    areaLimit: "",
    confirmDiscard: false,
  };

  polydrawer: L.Draw.Polygon | undefined;
  spdocref: DocumentReference;
  evdocref: DocumentReference;

  constructor(props: SpeedAreasEditorProps) {
    super(props);
    const fb = getFirestore();
    this.spdocref = doc(fb, "speedareas", getAuth().currentUser!.uid);
    if (props.eventId === null) {
      console.error("Eventid should not be null");
    }
    this.evdocref = doc(fb, "events", props.eventId!);
  }

  areaPolygonCreated = (levent: L.LeafletEvent) => {
    const event = levent as L.DrawEvents.Created;
    const layer = event.layer;
    if (layer instanceof L.Polygon) {
      this.setState({
        areaPoints: layer.getLatLngs()[0] as L.LatLng[],
      });
    }
  };
  drawVertex = (levent: L.LeafletEvent) => {
    const event = levent as L.DrawEvents.DrawVertex;
    this.setState({ numPointsInArea: event.layers.getLayers().length });
    console.log(event.layers.getLayers());
  };
  componentDidMount() {
    mainMap.on(L.Draw.Event.CREATED, this.areaPolygonCreated);
    mainMap.on(L.Draw.Event.DRAWVERTEX, this.drawVertex);
  }
  componentWillUnmount() {
    this.polydrawer?.disable();
    mainMap.off(L.Draw.Event.CREATED, this.areaPolygonCreated);
    this.polydrawer = undefined;
  }

  triggerAddNewArea = () => {
    let options = {
      allowIntersection: false,
      showArea: true,
      metric: true,
    };
    this.polydrawer =
      this.polydrawer || new L.Draw.Polygon(mainMap as L.DrawMap, options);
    if (!isMobile) {
      this.polydrawer.setOptions({
        icon: new L.DivIcon({
          iconSize: new L.Point(8, 8),
          className: "leaflet-div-icon leaflet-editing-icon leaflet-touch-icon",
        }),
      });
    }
    this.polydrawer.enable();
    this.setState({
      addNewArea: true,
      numPointsInArea: 0,
      areaPoints: undefined,
      areaName: "",
      areaRestricted: false,
      timedArea: false,
      areaLimit: "",
    });
  };
  clearAddOrEdit = (event?: any, force = false) => {
    console.log(force, this.state.editAreaId, this.hasEditChanges());
    if (!force && this.state.editAreaId && this.hasEditChanges()) {
      this.setState({ confirmDiscard: true });
      return false;
    }
    this.polydrawer?.disable();
    this.setState({
      addNewArea: false,
      editAreaId: undefined,
      areaPoints: undefined,
      areaName: "",
      areaLimit: "",
      areaRestricted: false,
      timedArea: false,
    });
    return true;
  };
  removeAreaFromEvent = (aid: string) => async () => {
    if (this.state.editAreaId === aid) this.clearAddOrEdit(null, true);
    this.props.execWithProgress(() => {
      // TODO Why am i not using here fsUpdate
      return updateDoc(this.evdocref, {
        ["speedareas." + aid]: deleteField(),
      });
    });
  };

  makeAreaObject = () => {
    if (!this.state.areaPoints) return null;
    let areaobject: AreaObjectType = {
      area: this.state.areaPoints.map((p) => new GeoPoint(p.lat, p.lng)),
      name: this.state.areaName,
    };
    if (this.state.areaRestricted) areaobject.restricted = true;
    else areaobject.limit = Number(this.state.areaLimit);
    if (this.state.timedArea) areaobject.timed = true;
    return areaobject;
  };

  saveNewArea = async () => {
    if (this.props.eventId === null) {
      console.error("required value is missing");
      return;
    }
    const uid = getAuth().currentUser?.uid;
    this.props.execWithProgress(async () => {
      const doc = await getDoc(this.spdocref);
      let areanum = 1;
      if (!doc.exists()) await setDoc(this.spdocref, {});
      else {
        const data = doc.data();
        if (data && data.speedareas) {
          Object.keys(data.speedareas).forEach((k) => {
            if (Number(k) >= areanum) {
              areanum = Number(k) + 1;
            }
          });
        }
      }
      const areaobject = this.makeAreaObject();
      await updateDoc(this.spdocref, {
        ["speedareas." + areanum.toString()]: areaobject,
      });
      await updateDoc(this.evdocref, {
        ["speedareas." + uid + ":" + areanum.toString()]: areaobject,
      });
      this.clearAddOrEdit(null, true);
    });
  };
  saveChanges = async () => {
    this.props.execWithProgress(async () => {
      if (!this.state.editAreaId) return;
      const areaobject = this.makeAreaObject();
      console.log("save area object", areaobject);
      await updateDoc(this.evdocref, {
        ["speedareas." + this.state.editAreaId]: areaobject,
      });
      const [auid, aid] = this.state.editAreaId.split(":");
      if (auid === getAuth().currentUser?.uid) {
        await updateDoc(this.spdocref, {
          ["speedareas." + aid]: areaobject,
        });
      }
      this.clearAddOrEdit(null, true);
    });
  };
  removeLastNewAreaPoint = () => {
    this.polydrawer?.deleteLastVertex();
  };
  finishNewArea = () => {
    this.polydrawer?.completeShape();
  };
  startEdit = (aid: string, flyToBounds: boolean) => () => {
    if (this.state.editAreaId && this.hasEditChanges()) {
      this.setState({ confirmDiscard: true, newEditAreaId: aid });
      return;
    }
    const aob = this.props.eventAreas[aid];
    if (!aob) return;
    const ap = aob.area.map((p) => new L.LatLng(p.latitude, p.longitude));
    const bounds = L.latLngBounds(ap);
    const firstClick = !(this.state.editAreaId === aid);
    if (flyToBounds && (!firstClick || !mainMap.getBounds().contains(bounds))) {
      mainMap.flyToBounds(bounds);
    }

    if (!firstClick) return;
    this.setState({
      editAreaId: aid,
      areaName: aob.name,
      areaRestricted: aob.restricted ? true : false,
      timedArea: aob.timed ? true : false,
      areaLimit: aob.limit?.toString() || "",
      areaPoints: ap,
    });
  };
  hasEditChanges = () => {
    if (!this.state.editAreaId) return false;
    const aob = this.props.eventAreas[this.state.editAreaId];
    if (!aob) return false;
    return (
      aob.name !== this.state.areaName ||
      (aob.limit?.toString() || "") !== this.state.areaLimit ||
      Boolean(aob.restricted) !== Boolean(this.state.areaRestricted) ||
      Boolean(aob.timed) !== Boolean(this.state.timedArea) ||
      aob.area.length !== this.state.areaPoints?.length ||
      this.state.areaPoints.find(
        (ap, idx) =>
          ap.lat !== aob.area[idx].latitude ||
          ap.lng !== aob.area[idx].longitude
      ) !== undefined
    );
  };
  editAreaChanged = (points: L.LatLng[]) => {
    this.setState({ areaPoints: points });
  };
  continueClear = () => {
    const aid = this.state.newEditAreaId;
    this.clearAddOrEdit(null, true);
    if (aid) {
      this.setState({ newEditAreaId: undefined });
      this.startEdit(aid, false)();
    }
    this.setState({ confirmDiscard: false });
  };
  render() {
    const { t } = this.props;
    const {
      areaName,
      areaLimit,
      areaRestricted,
      timedArea,
      addNewArea,
      editAreaId,
      areaPoints,
      numPointsInArea,
    } = this.state;
    const limitInputProps =
      areaLimit.length > 0 && !areaRestricted
        ? {
            inputProps: { className: "removeupdownarrow" },
            endAdornment: (
              <InputAdornment position="end" disableTypography>
                <Typography variant="subtitle1" sx={{ fontSize: 13 }}>
                  km
                  <Divider />h
                </Typography>
              </InputAdornment>
            ),
          }
        : {};
    const noEdit = !editAreaId && !addNewArea;
    const areaNameError = !noEdit && areaName.length === 0;
    const areaRestrictionError =
      !noEdit && !areaRestricted && areaLimit.length === 0;

    return (
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={1}
          sx={{ marginTop: 1, marginBottom: 1 }}
        >
          <Grid item>
            {addNewArea && !areaPoints && (
              <ButtonGroup
                size="small"
                variant="contained"
                orientation="vertical"
              >
                <Button
                  onClick={this.finishNewArea}
                  disabled={numPointsInArea < 3}
                >
                  {t("speedarea.Finish")}
                </Button>
                <Button
                  onClick={this.removeLastNewAreaPoint}
                  disabled={numPointsInArea < 2}
                >
                  {t("speedarea.removelastpoint")}
                </Button>
                <Button onClick={this.clearAddOrEdit}>
                  {t("speedarea.cancel")}
                </Button>
              </ButtonGroup>
            )}
            {!addNewArea && !editAreaId && (
              <Button variant="contained" onClick={this.triggerAddNewArea}>
                {t("speedarea.add")}
              </Button>
            )}
            {editAreaId && (
              <ButtonGroup
                size="small"
                variant="contained"
                orientation="vertical"
              >
                <Button
                  disabled={
                    areaRestrictionError ||
                    areaNameError ||
                    !this.hasEditChanges()
                  }
                  onClick={this.saveChanges}
                >
                  {t("speedarea.save")}
                </Button>
                <Button onClick={this.clearAddOrEdit}>
                  {t("speedarea.cancel")}
                </Button>
              </ButtonGroup>
            )}
            {addNewArea && areaPoints && (
              <ButtonGroup
                size="small"
                variant="contained"
                orientation="vertical"
              >
                <Button
                  disabled={areaRestrictionError || areaNameError}
                  onClick={this.saveNewArea}
                >
                  {t("speedarea.save")}
                </Button>
                <Button onClick={this.clearAddOrEdit}>
                  {t("speedarea.cancel")}
                </Button>
              </ButtonGroup>
            )}
          </Grid>
          <Grid item>
            <TextField
              variant="standard"
              label={t("speedarea.label.name")}
              disabled={noEdit}
              value={areaName}
              onChange={(event) =>
                this.setState({ areaName: event.target.value })
              }
              error={areaNameError}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={5}>
                <FormControlLabel
                  label={t("speedarea.restricted")}
                  control={
                    <Checkbox
                      disabled={noEdit}
                      checked={areaRestricted}
                      value={areaRestricted}
                      onChange={(event) =>
                        this.setState({ areaRestricted: event.target.checked })
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={t("speedarea.limit.label")}
                  value={areaRestricted ? "" : areaLimit}
                  disabled={noEdit || areaRestricted}
                  type="number"
                  error={areaRestrictionError}
                  onChange={(event) => {
                    this.setState({ areaLimit: event.target.value });
                  }}
                  sx={{
                    "& .removeupdownarrow": {
                      "&::-webkit-outer-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                      "&::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    },
                  }}
                  slotProps={{
                    input: limitInputProps,
                  }}
                />
              </Grid>
              {this.props.isRoadBookEvent && (
                <Grid item xs={5}>
                  <FormControlLabel
                    label={t("area.timed.label")}
                    control={
                      <NLTooltip message={t("area.timed.tooltip")}>
                        <Checkbox
                          disabled={noEdit}
                          checked={timedArea}
                          value={timedArea}
                          onChange={(event) =>
                            this.setState({ timedArea: event.target.checked })
                          }
                        />
                      </NLTooltip>
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <List
          sx={{
            position: "relative",
            minHeight: "30vh", // Investigate react-split-pane if it's better to vsize the area boxes
            maxHeight: "40vh",
            overflow: "auto",
          }}
          dense
          subheader={
            <ListSubheader color="primary" component="div">
              {t("speedareas.inevent")}
            </ListSubheader>
          }
        >
          {this.props.eventAreas &&
            Object.entries(this.props.eventAreas).map(([aid, area]) => (
              <ListItemButton
                key={aid}
                onClick={this.startEdit(aid, true)}
                selected={aid === editAreaId}
              >
                <ListItemText
                  secondary={
                    area.restricted
                      ? t("speedarea.restricted")
                      : area.limit + " km/h"
                  }
                >
                  {area.name}
                </ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={this.removeAreaFromEvent(aid)}
                    size="large"
                  >
                    <RemoveCircleRounded />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
            ))}
        </List>
        <Divider />
        {areaPoints && areaPoints.length > 2 && (
          <SpeedArea
            key={editAreaId || "newarea"}
            points={areaPoints}
            map={mainMap}
            enableEdit={Boolean(editAreaId)}
            onChange={this.editAreaChanged}
          />
        )}
        {Object.entries(this.props.eventAreas)
          .filter(([aid]) => aid !== editAreaId)
          .map(([aid, area]) => (
            <SpeedArea
              key={aid}
              points={area.area.map(
                (p) => new L.LatLng(p.latitude, p.longitude)
              )}
              color={green[600]}
              map={mainMap}
              enableEdit={false}
              onClick={() => {
                this.startEdit(aid, false)();
              }}
            />
          ))}
        <Dialog open={this.state.confirmDiscard}>
          <DialogTitle>{t("speedareas.confirmdiscardtitle")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("speedareas.confirmdiscardtext")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.continueClear}>
              {t("button.discard")}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.saveChanges().then(() => {
                  this.continueClear();
                });
              }}
            >
              {t("button.save")}
            </Button>
            <Button
              color="primary"
              onClick={() => this.setState({ confirmDiscard: false })}
            >
              {t("button.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connector(withTranslation()(SpeedAreasEditor));
