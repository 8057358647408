import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps, ReactReduxContext } from "react-redux";
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import DrawerHeader from "./DrawerHeader";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AutoSizer, { HorizontalSize } from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { withNL, WithNLProps } from "utils/NLContext";
import SpeedArea from "./SpeedArea";
import { green } from "@mui/material/colors";
import * as L from "leaflet";
import { execWithProgress } from "rx/saveInProgressSlice";
import { TrackPoint } from "rx/tracksSlice";
import { isPointInArea } from "rx/simpleFirstoreDocSlice";
import { TeamSpdTickets } from "rx/fbListSlices";
import TeamSpeedTrack from "utils/TeamSpeedTrack";
import { child, getDatabase, ref, set } from "firebase/database";

type TicketType = RootState["spdTickets"][string][string][string];

const mapState = (state: RootState) => ({
  tickets: state.spdTickets,
  teamsList: state.teamsList,
  areas: state.eventDoc?.speedareas || {},
  isAdmin: state.user.eventAccess,
  eventId: state.eventId,
  eventAreas: state.eventDoc?.speedareas || {},
});
const mapDispatch = {
  execWithProgress: (f: (getState: () => RootState) => Promise<any>) =>
    execWithProgress(f),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type SpeedingDrawerProps = PropsFromRedux &
  WithTranslation &
  WithNLProps & {
    closeDrawer: () => void;
    viewOnly?: boolean;
  };
type SpeedingDrawerState = {
  showAreas: boolean;
  expand: {
    [tid: string]: boolean;
  };
  currentTicket?: {
    tid: string;
    devid: string;
    tstamp: number;
  };
};
class SpeedingDrawer extends React.Component<
  SpeedingDrawerProps,
  SpeedingDrawerState
> {
  static contextType = ReactReduxContext;
  state: SpeedingDrawerState = {
    showAreas: false,
    expand: {},
  };
  handleExpand = (tid: string) => () => {
    let newExpand = { ...this.state.expand };
    newExpand[tid] = !newExpand[tid];
    this.setState({ expand: newExpand });
  };
  handleTicketChange =
    (tid: string, devid: string, tstamp: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = "enabled";
      if (event.target.value === "enabled") value = "ignored";
      else if (event.target.value === "ignored") value = "";

      this.props.fbSet(
        `teams/${this.props.eventId}/spdtickets/${tid}/${devid}/${tstamp}/state`,
        value
      );
    };
  showAreaInfo = (aid: string) => () => {};
  showTicket = (tid: string, devid: string, tstamp: number) => () => {
    this.setState({
      currentTicket: { tid: tid, devid: devid, tstamp: tstamp },
    });
  };
  ticketRow =
    (tid: string, devid: string) =>
    ({ index, style }: ListChildComponentProps) => {
      const [tstamp, item] = this.ticketsForTeam(tid, devid)[index];
      const currentTicket = this.state.currentTicket;
      const selected =
        currentTicket &&
        currentTicket.tid === tid &&
        currentTicket.devid === devid &&
        currentTicket.tstamp === tstamp;
      let secondary = Math.floor(item.spd || 0) + " km/h";
      if (item.area) {
        const area = this.props.areas[item.area];
        if (area === undefined) {
          secondary += " - " + this.props.t("speedareas.areadeleted");
        } else {
          if (area.restricted)
            secondary =
              this.props.t("speedareas.restrictedprefix") + " " + area.name;
          else secondary += " - " + area.name;
        }
      }
      return (
        <ListItemButton
          selected={selected}
          style={style}
          onClick={this.showTicket(tid, devid, tstamp)}
        >
          {!this.props.viewOnly && (
            <ListItemIcon>
              <Checkbox
                value={item.state}
                checked={item.state === "enabled"}
                indeterminate={item.state === "ignored"}
                onChange={this.handleTicketChange(tid, devid, tstamp)}
              />
            </ListItemIcon>
          )}
          <ListItemText
            secondary={new Date(Number(tstamp)).toLocaleTimeString(undefined, {
              hour12: false,
            })}
          >
            <Typography variant="subtitle2" noWrap>
              {secondary}
            </Typography>
          </ListItemText>
        </ListItemButton>
      );
    };

  ticketsForTeam = (tid: string, devid: string) => {
    const team = this.props.teamsList[tid];
    if (!team) return [];

    const tickets = this.props.tickets[tid][devid];
    return Object.entries(tickets)
      .map(([t, ticket]) => [Number(t), ticket] as [number, typeof ticket])
      .filter(
        ([t, ticket]) =>
          (!team.starttime || t > team.starttime) &&
          (!team.finishtime || t < team.finishtime) &&
          (!this.props.viewOnly || ticket.state === "enabled")
      );
  };

  recalculateTickets = async () => {
    console.log("recalculate");
    this.props.execWithProgress(async (getState: () => RootState) => {
      let resp = new Promise<void>((resolve) => {
        setTimeout(async () => {
          const state = getState();
          const dbspconf = state.eventDoc?.speed;
          if (!dbspconf) {
            console.error("Missing speed configuration");
            return;
          }
          let spconf = {
            limit: dbspconf.limit || 90,
            over1: dbspconf.over1 || 0,
            over2: dbspconf.over2 || 10,
            delay1: dbspconf.delay1 || 10,
            delay2: dbspconf.delay2 || 5,
          };

          const db = getDatabase();
          const teamsref = ref(db, `teams/${state.eventId}/`);
          const archref = child(teamsref, "sticketarchive");
          await set(
            child(archref, new Date().getTime().toString()),
            state.spdTickets
          );
          const oldTickets = JSON.parse(JSON.stringify(state.spdTickets));
          const oldTicketState = (tid: string, devid: string, t: number) => {
            if (
              oldTickets[tid] &&
              oldTickets[tid][devid] &&
              oldTickets[tid][devid][t]
            )
              return oldTickets[tid][devid][t].state;
            return null;
          };
          let newtickets: {
            [tid: string]: TeamSpdTickets;
          } = {};
          // We could theoretically put each element here behind setTimeout to get
          // better responsivnes from ui.
          Object.entries(state.tracks).forEach(([tid, devs]) => {
            if (tid === "upcount") return;
            const team = state.teamsList[tid];
            if (!team || !team.starttime) return;
            const start = team.starttime;
            const finisht = team.finishtime;
            Object.entries(devs).forEach(([devid, pdata]) => {
              let oc1 = 0;
              let oc2 = 0;
              const addTicket = (
                l: number,
                p: TrackPoint,
                type: string,
                area?: string
              ) => {
                if (!(tid in newtickets)) newtickets[tid] = {};
                if (!(devid in newtickets[tid])) newtickets[tid][devid] = {};
                newtickets[tid][devid][p.t.toString()] = {
                  limit: l,
                  spd: p.s,
                  type: type,
                };
                const oldst = oldTicketState(tid, devid, p.t);
                if (oldst) newtickets[tid][devid][p.t.toString()].state = oldst;
                if (area) newtickets[tid][devid][p.t.toString()].area = area;
              };
              pdata.forEach((p) => {
                if (p.t < start || (finisht && p.t > finisht)) return;
                let inarea = Object.entries(
                  state.eventDoc?.speedareas || {}
                ).find(([_, s]) => isPointInArea(p, s));
                let [aid, area] = inarea || [undefined, undefined];
                if (area && area.restricted) {
                  addTicket(0, p, "restricted", aid);
                } else {
                  let limit = area && area.limit ? area.limit : spconf.limit;
                  if (p.s >= limit + spconf.over2) {
                    oc2 += 1;
                    if (oc2 >= spconf.delay2) {
                      addTicket(limit, p, "co2", aid);
                      oc2 = 0;
                      oc1 = 0;
                    }
                  } else oc2 = 0;
                  if (p.s >= limit + spconf.over1) {
                    oc1 += 1;
                    if (oc1 >= spconf.delay1) {
                      addTicket(limit, p, "co1", aid);
                      oc2 = 0;
                      oc1 = 0;
                    }
                  } else oc1 = 0;
                }
              });
            });
          });
          await this.props.fbSet(child(teamsref, "spdtickets"), newtickets);
          resolve();
        });
      });
      return resp;
    });
  };
  partialChecked = (tickets: [number, TicketType][]) => {
    let havedecided = false;
    let haveundecided = false;
    tickets.forEach(([_, ticket]) => {
      if (ticket.state && ["enabled", "ignored"].includes(ticket.state))
        havedecided = true;
      else haveundecided = true;
    });
    return havedecided === haveundecided;
  };
  toggleAllTickets =
    (tickets: [number, TicketType][], tid: string, devid: string) => () => {
      let shouldcheck = this.partialChecked(tickets);
      if (!shouldcheck && tickets[0][1].state === "") shouldcheck = true;

      tickets.forEach(([t, ticket]) => {
        if (ticket.state === "ignored") return;
        if (shouldcheck && ticket.state !== "enabled") {
          this.props.fbSet(
            `teams/${this.props.eventId}/spdtickets/${tid}/${devid}/${t}/state`,
            "enabled"
          );
        }
        if (!shouldcheck && ticket.state === "enabled") {
          this.props.fbSet(
            `teams/${this.props.eventId}/spdtickets/${tid}/${devid}/${t}/state`,
            ""
          );
        }
      });
    };

  render() {
    const { t, tickets, teamsList } = this.props;
    const { currentTicket } = this.state;
    let listitems: React.ReactElement[] = [];
    Object.entries(tickets).forEach(([tid, devs]) => {
      //console.log("tid", tid);
      const team = teamsList[tid];
      if (!team) return;
      let usedev = Object.keys(devs)[0];
      const teamtickets = this.ticketsForTeam(tid, usedev);
      if (teamtickets.length === 0) return;
      /*
            Object.entries(devs).forEach(([devid, tickets]) => {
              Object.entries(tickets).forEach(([tstamp, ticket]) => {
                //console.log("at", tstamp, "ticket", ticket);
              });
            });
            */
      // TODO If listitem is button then it cant have secondary action.
      listitems.push(
        <ListItemButton key={tid} onClick={this.handleExpand(tid)}>
          {this.state.expand[tid] ? <ExpandLess /> : <ExpandMore />}
          <ListItemText
            color="primary"
            primary={
              (teamsList[tid] || {}).name + " (" + teamtickets.length + ")"
            }
          />
          {!this.props.viewOnly && (
            <ListItemSecondaryAction>
              <Checkbox
                checked={
                  !Boolean(
                    teamtickets.find(
                      ([_, ticket]) =>
                        !["enabled", "ignored"].includes(ticket.state || "")
                    )
                  )
                }
                indeterminate={this.partialChecked(teamtickets)}
                onChange={this.toggleAllTickets(teamtickets, tid, usedev)}
              />
            </ListItemSecondaryAction>
          )}
        </ListItemButton>
      );

      listitems.push(
        <Collapse key={"c" + tid} in={this.state.expand[tid]}>
          <AutoSizer disableHeight>
            {({ width }: HorizontalSize) => (
              <FixedSizeList
                width={width || 0}
                height={200}
                itemSize={45}
                itemCount={teamtickets.length}
              >
                {this.ticketRow(tid, usedev)}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Collapse>
      );
    });
    return (
      <>
        <DrawerHeader
          closeDrawer={this.props.closeDrawer}
          title={
            this.props.viewOnly
              ? t("menu.title.speedings")
              : t("menu.title.speedingsadmin")
          }
        >
          <Grid container spacing={2} justifyContent="center">
            {!this.props.viewOnly && (
              <Grid item>
                <Button variant="contained" onClick={this.recalculateTickets}>
                  {t("speedings.recalculate")}
                </Button>
              </Grid>
            )}
            <Grid item>
              <FormControlLabel
                label={t("speedings.showareas")}
                control={
                  <Switch
                    checked={this.state.showAreas}
                    onChange={(event) =>
                      this.setState({ showAreas: event.target.checked })
                    }
                  />
                }
              />
            </Grid>
          </Grid>
          <List>{listitems}</List>
          {this.state.showAreas &&
            Object.entries(this.props.eventAreas).map(([aid, area]) => (
              <SpeedArea
                key={aid}
                points={area.area.map(
                  (p) => new L.LatLng(p.latitude, p.longitude)
                )}
                color={green[600]}
                map={mainMap}
                enableEdit={false}
                onClick={() => {
                  this.showAreaInfo(aid)();
                }}
              />
            ))}
        </DrawerHeader>
        {currentTicket && (
          <TeamSpeedTrack
            key={currentTicket.tid + currentTicket.devid}
            tid={currentTicket.tid}
            devid={currentTicket.devid}
            tickettime={currentTicket.tstamp}
            map={mainMap}
          />
        )}
      </>
    );
  }
}

export default connector(withTranslation()(withNL(SpeedingDrawer)));
