import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { KP, KPdata } from "rx/fbListSlices";
import { useDispatch, useStore } from "react-redux";
import { RootState } from "rx/store";
import { SlideUpTransition } from "utils/DialogUtils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { parse as csvparse } from "csv-parse/sync";

import {
  decrementProgressCount,
  incrementProgressCount,
} from "rx/saveInProgressSlice";
import { child, getDatabase, push, ref, set, update } from "firebase/database";

const classes = {
  newdata: `newdata`,
  overwritedata: `overwritedata`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.newdata}`]: {
    backgroundColor: "green",
  },

  [`& .${classes.overwritedata}`]: {
    backgroundColor: "red",
  },
}));

type ImportKPData = {
  kpList: {
    [kpid: string]: KP;
  };
  kpAnswers: {
    [kpid: string]: string;
  };
  kpData: {
    [kpid: string]: KPdata;
  };
};

const detectKPid = (kp: KP, state: RootState) => {
  if (kp.nr.toString().length > 0) {
    // TODO test if kp.nr.toString().length is the thing i wanted to do here
    const found = Object.entries(state.kpList).find(
      ([_, skp]) => skp.nr === kp.nr
    );
    if (found) return found[0];
  }
  if (!state.eventId) return "-1";
  const newid = push(
    ref(getDatabase(), "/eventsdata/" + state.eventId + "/kp")
  ).key;
  if (!newid) console.error("Failed to push new id into firebase");
  return newid || "badpush";
};

const tryCSV = (contents: string, state: RootState) => {
  let options = { trim: true, delimiter: ",", relax_column_count: true };
  let entries = [];
  try {
    entries = csvparse(contents, options);
  } catch (e) {}
  if (entries.length === 0 || entries[0].length < 2) {
    options.delimiter = ";";
    try {
      entries = csvparse(contents, options);
    } catch (e) {
      // We don't have show alert yet.
      //this.props.showAlert("CSV fail on vigane", "Vigane CSV");
      return null;
    }
  }
  let csvHeaderMap: { [k: string]: string } = {
    raskusaste: "ra",
    location: "loc",
  };
  type CsvFields = keyof KP | keyof KPdata | "id" | "tahis";
  let fields: CsvFields[] = [
    "id",
    "nr",
    "ra",
    "kohustuslik",
    "desc",
    "longdesc",
    "loc",
    "tyhistatud",
    "maxanswerdistance",
    "allok",
    "isauto",
    "wrongpenalty",
    "tahis",
    "responses",
  ];
  if (!Array.isArray(entries)) {
    console.error("unhandled csvparse output");
    return false;
  }

  let resp: ImportKPData = { kpList: {}, kpData: {}, kpAnswers: {} };
  entries.forEach((e: string[]) => {
    if (e.length < 4) return;
    if (e[0] === "id" && e[1].startsWith("nr")) {
      // Rewrite fields
      fields = e
        .map((f) => (f.endsWith("_") ? f.replace(/_$/, "") : f))
        .map((f) =>
          csvHeaderMap[f] !== undefined ? csvHeaderMap[f] : f
        ) as CsvFields[];
      return;
    } // Skip header if exists
    let kpl: KP = { nr: "" };
    let kpd: KPdata = {};
    let answer: string | undefined;
    let kpid: string | undefined;
    fields.forEach((f, idx) => {
      if (idx >= e.length) return;

      if (f === "id") {
        if (e[idx].length > 0) kpid = e[idx];
      } else if (
        f === "kohustuslik" ||
        f === "tyhistatud" ||
        f === "allok" ||
        f === "isauto" ||
        f === "anycross" ||
        f === "noopenwhennotclose" ||
        f === "inwork"
      ) {
        if (
          ["true", "1", "ok", "jah", "y", "yes"].includes(e[idx].toLowerCase())
        ) {
          if (f === "anycross") kpd[f] = true;
          else kpl[f] = true;
        }
      } else if (
        f === "ra" ||
        f === "grp" ||
        f === "maxanswerdistance" ||
        f === "wrongpenalty"
      ) {
        if (e[idx].trim() !== "") {
          let numval = Number(e[idx]);
          if (!isNaN(numval)) kpl[f] = numval;
        }
      } else if (f === "loc" || f === "a") {
        let ll = e[idx].split(" ");
        if (ll.length !== 2) return;
        kpd[f] = {
          lat: Number(
            ll[0][0] === "S"
              ? "-" + ll[0].substring(1)
              : ll[0][0] === "N"
                ? ll[0].substring(1)
                : ll[0]
          ),
          lng: Number(
            ll[1][0] === "W"
              ? "-" + ll[1].substring(1)
              : ll[1][0] === "E"
                ? ll[1].substring(1)
                : ll[1]
          ),
        };
        return;
      } else if (f === "nr") {
        kpl[f] = e[idx];
      } else if (f === "tahis") {
        answer = e[idx];
      } else if (f === "dependkp") {
        kpl.dependkp = e[idx];
      } else if (f === "responses") {
        try {
          kpd.responses = JSON.parse(e[idx]);
        } catch (error) {
          kpd.responses = {};
          for (let resp of e[idx].split(",")) {
            kpd.responses["R" + Object.keys(kpd.responses).length + 1] = resp;
          }
        }
      } else if (e[idx]) kpd[f] = e[idx];
    });

    if (!(kpd.responses && answer && kpd.responses[answer] !== undefined)) {
      // If responses was not json, try different other things
      for (let i = fields.length; i < e.length; i++) {
        if (!kpd.responses) kpd.responses = {};
        if (e[i]) kpd.responses["R" + i] = e[i];
      }

      if (kpd.responses && Object.keys(kpd.responses).length === 1) {
        const responses = Object.values(kpd.responses);
        const parts = responses[0].split(",");
        kpd.responses = {};
        parts.forEach((pentry, idx) => {
          kpd.responses![idx] = pentry.trim();
        });
      }

      if (kpd.responses) {
        if (Object.keys(kpd.responses).length === 0) {
          delete kpd.responses;
        } else if (Object.values(kpd.responses).every((k) => k.includes(":"))) {
          let convresponses = kpd.responses;
          Object.entries(kpd.responses).forEach(([key, value]) => {
            let parts = value.split(":");
            if (parts.length !== 2) return;
            delete convresponses[key];
            convresponses[parts[0]] = parts[1];
          });
          kpd.responses = convresponses;

          if (answer && !Object.keys(kpd.responses).includes(answer)) {
            answer = undefined;
          }
        } else {
          if (answer && !Object.values(kpd.responses).includes(answer)) {
            const rkey =
              "R" +
              Math.floor(
                Math.random() * (Object.keys(kpd.responses).length + 1)
              );
            kpd.responses[rkey] = answer;
            answer = rkey;
          }
          if (!answer) {
            const responses = kpd.responses;
            const correctkey = Object.keys(kpd.responses).find((k) =>
              responses[k].startsWith("*")
            );
            if (correctkey) {
              kpd.responses[correctkey] =
                kpd.responses[correctkey].substring(1);
              answer = correctkey; // kpd.responses[correctkey];
            }
          }
        }
      }
    }
    if (!kpid) kpid = detectKPid(kpl, state);
    resp.kpList[kpid] = kpl;
    resp.kpData[kpid] = kpd;
    if (answer) resp.kpAnswers[kpid] = answer;
  });
  return resp;
};

const PresentInputKPs: React.FC<{
  inputdata: string | null;
  previewOpen: boolean;
  onCompleted: () => void;
}> = ({ inputdata, previewOpen, onCompleted }) => {
  const store = useStore<RootState>();
  const { t } = useTranslation();
  const [deleteExisting, setDeleteExisting] = useState(false);
  const [mergeKps, setMergeKps] = useState(false);

  const kpdata = useMemo(() => {
    if (!inputdata) return undefined;
    try {
      const jdata = JSON.parse(inputdata);
      return jdata as ImportKPData;
    } catch (e) {}

    const state = store.getState();
    let parser = new DOMParser();
    let doc = parser.parseFromString(inputdata, "text/xml");
    let wpts = doc.getElementsByTagName("wpt");
    let result: ImportKPData = { kpList: {}, kpAnswers: {}, kpData: {} };
    for (let i = 0; i < wpts.length; i++) {
      let kpl: KP = { nr: "" };
      let kpd: KPdata = {};
      let answer: string | undefined;
      let kpid: string | undefined;
      const wpt = wpts[i];
      wpt.childNodes.forEach((n) => {
        if (!n.textContent) return;
        if (n.nodeName === "desc") kpd.desc = n.textContent;
        else if (n.nodeName === "name") kpl.nr = n.textContent;
        else if (n.nodeName === "sym") {
          if (n.textContent.includes("Triangle")) kpl.ra = 2;
          else if (n.textContent.includes("Square")) kpl.ra = 3;
          else if (n.textContent.includes("Circle")) kpl.ra = 1;
          else if (!isNaN(Number(n.textContent)))
            kpl.ra = Number(n.textContent);
          else kpl.ra = 1;
        } else if (n.nodeName === "cmt") {
          answer = n.textContent;
        }
        if (n.nodeName === "extensions") {
          n.childNodes.forEach((en) => {
            if (!en.textContent) return;
            if (en.nodeName === "serverid") kpid = en.textContent;
            else if (en.nodeName === "responses") {
              let responses: Exclude<typeof kpd.responses, undefined> = {};
              en.childNodes.forEach((rn) => {
                if (!rn.textContent || rn.nodeName !== "response") return;
                const rid = (rn as Element).getAttribute("id");
                responses[rid || "R" + Object.keys(responses).length + 1] =
                  rn.textContent;
              });
              kpd.responses = responses;
            } else if (en.nodeName === "maxanswerdistance")
              kpl.maxanswerdistance = Number(en.textContent);
            else if (en.nodeName === "allok")
              kpl.allok = en.textContent === "true";
          });
        }
      });
      let lat = wpt.getAttribute("lat");
      let lng = wpt.getAttribute("lon");
      if (lat && lng) kpd.loc = { lat: parseFloat(lat), lng: parseFloat(lng) };
      if (!kpid) {
        kpid = detectKPid(kpl, state);
      }
      result.kpList[kpid] = kpl;
      result.kpData[kpid] = kpd;
      if (answer) result.kpAnswers[kpid] = answer;
    }

    if (Object.keys(result.kpList).length > 0) return result;

    return tryCSV(inputdata, state);
  }, [inputdata, store]);
  //console.log(sourcetype); // Should probaly do something more meaningful with sourcetype

  let importrows: JSX.Element[] = [];
  if (kpdata) {
    const getanswer = (k: string, data: ImportKPData) => {
      const kpAnswers = data.kpAnswers;
      const kpData = data.kpData;
      if (k in kpAnswers) {
        const answer = kpData[k]?.responses?.[kpAnswers[k]];
        if (answer) {
          return answer;
        } else return kpAnswers[k];
      }
      return "";
    };
    const getresponses = (k: string, data: ImportKPData) => {
      const kpData = data.kpData;
      if (k in kpData) {
        const resps = kpData[k].responses;

        if (resps) {
          return Object.values(resps).join(",");
        }
      }
      return "";
    };
    const state = store.getState() as RootState;
    importrows = Object.entries(kpdata.kpList).map(([k, kpl]) => {
      const cellRender = (newv: string, oldv?: string) => {
        if (mergeKps && (!newv || newv.length === 0) && oldv) newv = oldv;
        if (deleteExisting) oldv = newv;
        return (
          <TableCell
            className={
              oldv && oldv !== newv ? classes.overwritedata : undefined
            }
          >
            {newv}
          </TableCell>
        );
      };
      const ListCellRender = (field: keyof KP) => {
        return cellRender(
          kpl[field]?.toString() || "",
          state.kpList[k] ? state.kpList[k][field]?.toString() : ""
        );
      };
      const DataCellRender = (field: keyof KPdata) => {
        return cellRender(
          (kpdata.kpData[k] || {})[field]?.toString() || "",
          (state.kpData[k] || {})[field]?.toString()
        );
      };
      const renderlatlng = (kpdata?: KPdata) => {
        if (!kpdata) return "";
        return (kpdata.loc?.lat || "") + ", " + (kpdata.loc?.lng || "");
      };
      return (
        <TableRow
          key={k}
          className={state.kpList[k] ? undefined : classes.newdata}
        >
          {ListCellRender("nr")}
          {ListCellRender("ra")}
          {DataCellRender("desc")}
          {DataCellRender("longdesc")}
          {cellRender(getanswer(k, kpdata), getanswer(k, state))}
          {cellRender(
            renderlatlng(kpdata.kpData[k]),
            renderlatlng(state.kpData[k])
          )}
          {cellRender(getresponses(k, kpdata), getresponses(k, state))}
        </TableRow>
      );
    });
  }
  const dispatch = useDispatch();
  const doImport = async () => {
    if (!kpdata) {
      console.error("Nothing to import");
      return;
    }
    dispatch(incrementProgressCount());
    // TODO make backup before import.
    const state = store.getState();
    const eventref = ref(getDatabase(), `/eventsdata/${state.eventId}`);
    if (!deleteExisting) {
      if (mergeKps) {
        // This should be tested if it's merge or not.
        if (kpdata.kpList) await update(child(eventref, "kp"), kpdata.kpList);
        if (kpdata.kpAnswers)
          await update(child(eventref, "kpanswer"), kpdata.kpAnswers);
        if (kpdata.kpData)
          await update(child(eventref, "kpdata"), kpdata.kpData);
      }
    } else {
      if (kpdata.kpList) await set(child(eventref, "kp"), kpdata.kpList);
      if (kpdata.kpAnswers)
        await set(child(eventref, "kpanswer"), kpdata.kpAnswers);
      if (kpdata.kpData) await set(child(eventref, "kpdata"), kpdata.kpData);
    }

    dispatch(decrementProgressCount());
    onCompleted();
  };
  return (
    <Dialog
      open={Boolean(kpdata) && previewOpen}
      maxWidth="lg"
      TransitionComponent={SlideUpTransition}
    >
      {Boolean(kpdata) && (
        <Root>
          <DialogTitle id="import-kps-title">{t("importkp.title")}</DialogTitle>
          <DialogContent>
            <Tooltip title={t("importkp.deleteexisting.tooltip") || ""}>
              <FormControlLabel
                control={
                  <Switch
                    checked={deleteExisting}
                    onChange={(ev) => setDeleteExisting(ev.target.checked)}
                  />
                }
                label={t("importkp.deleteexisting.label")}
              />
            </Tooltip>
            {!deleteExisting && (
              <Tooltip title={t("importkp.mergekps-tooltip") || ""}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={mergeKps}
                      onChange={(ev) => setMergeKps(ev.target.checked)}
                    />
                  }
                  label={t("importkp.mergekps")}
                />
              </Tooltip>
            )}
            <TableContainer style={{ height: "70vh" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Jnr</TableCell>
                    <TableCell>Raskusaste</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Long Description</TableCell>
                    <TableCell>Vastus</TableCell>
                    <TableCell>Lat/Long</TableCell>
                    <TableCell>Responses</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{importrows}</TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button
              key="import"
              variant="contained"
              onClick={doImport}
              color="primary"
            >
              {t("button.doimport")}
            </Button>

            <Button
              key="cancel"
              variant="contained"
              onClick={onCompleted}
              color="primary"
            >
              {t("button.cancel")}
            </Button>
          </DialogActions>
        </Root>
      )}
    </Dialog>
  );
};
const KPDataImportHandler: React.FC<{
  trigger: boolean;
  handleClose: () => void;
}> = ({ trigger, handleClose }) => {
  const [previewOpen, setPreviewOpen] = useState(true);

  const inputfile = useRef<HTMLInputElement>(null);
  const [inputData, setInputData] = useState(null as string | null);
  const importKPs = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    var files = e.target.files;
    let usingiso8859 = false;
    var reader = new FileReader();
    reader.onload = function (ev) {
      if (!ev.target) return;
      var contents = ev.target.result;
      if (!contents) return;
      if (contents instanceof ArrayBuffer) {
        console.error("Handling of array buffer is unimplemented");
        return;
      }
      var i = contents.length;
      let havebadcode = false;
      while (i--) {
        if (contents.charCodeAt(i) === 65533) {
          havebadcode = true;
        }
      }
      if (havebadcode) {
        if (usingiso8859) {
          alert("bad fail.....");
          return;
        }
        usingiso8859 = true;
        reader.readAsText(files[0], "ISO-8859-1");
        return;
      }
      setInputData(contents);
      //self.importKPsFromString(contents);
    };
    reader.readAsText(files[0]);
  };
  useEffect(() => {
    if (trigger) {
      if (inputfile?.current?.value) inputfile.current.value = "";
      inputfile?.current?.click();
      setPreviewOpen(true);
      setInputData(null);
      handleClose();
    }
  }, [inputfile, trigger, handleClose]);
  return (
    <>
      <input
        ref={inputfile}
        type="file"
        style={{ display: "none" }}
        onChange={importKPs}
      />
      {
        <PresentInputKPs
          inputdata={inputData}
          previewOpen={previewOpen}
          onCompleted={() => setPreviewOpen(false)}
        />
      }
    </>
  );
};

export default KPDataImportHandler;
