import store, { useTSelector, RootState } from "rx/store";
import { useTraceUpdate } from "./SmallUtils";
import { DependencyList, useEffect, useMemo, useRef } from "react";
import { isDeepEqual } from "@mui/x-data-grid/internals";
import { KP, KPdata } from "rx/fbListSlices";
import { shallowEqual } from "react-redux";
import dayjs from "dayjs";

// Will give a new value only if the value from memo is not deepequal with previous value.
const useResultDeepEqMemo = <T>(factory: () => T, deps: DependencyList): T => {
    const currentRef = useRef<T>();
    const newvalue = useMemo(factory, [...deps, factory]);
    if (currentRef.current !== undefined && isDeepEqual(currentRef.current, newvalue)) return currentRef.current;
    currentRef.current = newvalue;
    return newvalue;
}

type TeamAnswers = RootState['teamAnswers'][string];
type KPCorrectAnswers = RootState['kpAnswers'];
type RAKoef = RootState['event']['rakoef'];
type FirstBonusList = RootState['event']['firstbonus'];
type KPList = RootState['kpList'];


type TeamResult = {
    tid: string,
    kps: {
        id: string,
        kp: KP,
        kpdata?: KPdata,
        kpanswer?: string,
        correctanswer?: string,
        aeg?: number,
        marked: boolean,
        response?: string,
        correct: boolean,
        bonus: number,
        penalty: number,
        points: number,
        value: number,
    }[]
}

export type TeamResultWithSummaries = TeamResult & {
    kokku: number,
    marks: number,
    correct: number,
    wrong: number,
    bonus: number,
    penalty: number
}
export const getValikTeamResult = (tid: string, callback: (result: TeamResultWithSummaries) => void) => {
    console.log("getValikTeamResult", tid);
    const prevState: {
        teamAnswers?: TeamAnswers,
        kpList?: KPList,
        kpData?: { [k: string]: KPdata },
        kpAnswers?: KPCorrectAnswers,
        rakoef?: RAKoef,
        answerscores?: boolean,
        wrongpenaltyenabled?: boolean,
        wrongcoef?: number,
        firstbonus?: FirstBonusList,
        firstbonusismultiplier?: boolean,
        allTeamAnswers?: { [atid: string]: TeamAnswers },
        sharedrakoef?: string,
    } = {}
    let prevResult: TeamResult = { tid: tid, kps: [] }

    const calculate = () => {
        const current = store.getState();
        if (prevState.teamAnswers === current.teamAnswers[tid] &&
            prevState.kpList === current.kpList &&
            prevState.kpData === current.kpData &&
            prevState.kpAnswers === current.kpAnswers &&
            prevState.rakoef === current.event.rakoef &&
            prevState.answerscores === current.event.answerscores &&
            prevState.wrongpenaltyenabled === current.event.wrongpenaltyenabled &&
            prevState.wrongcoef === current.event.wrongcoef &&
            prevState.firstbonus === current.event.firstbonus &&
            prevState.firstbonusismultiplier === current.event.firstbonusismultiplier &&
            prevState.sharedrakoef === current.event.sharedrakoef && (
                current.event.firstbonus !== undefined &&
                prevState.allTeamAnswers === current.teamAnswers
            )
        ) {
            return;
        }

        prevState.teamAnswers = current.teamAnswers[tid];
        prevState.kpList = current.kpList;
        prevState.kpData = current.kpData;
        prevState.kpAnswers = current.kpAnswers;
        prevState.rakoef = current.event.rakoef;
        prevState.answerscores = current.event.answerscores;
        prevState.wrongpenaltyenabled = current.event.wrongpenaltyenabled;
        prevState.wrongcoef = current.event.wrongcoef;
        prevState.firstbonus = current.event.firstbonus;
        prevState.firstbonusismultiplier = current.event.firstbonusismultiplier;
        prevState.sharedrakoef = current.event.sharedrakoef;
        prevState.allTeamAnswers = current.teamAnswers;

        const result: TeamResult = {
            tid: tid,
            kps: Object.entries(current.kpList).map(([kpid, kp]) => {
                let answer = prevState?.teamAnswers?.[kpid];
                // correct detected should be not from kpAnswer as it is not available when not logged in.
                const anyok = Boolean(
                  kp.allok || kp.isauto || current.kpData?.[kpid]?.a
                );
                let correct = Boolean(answer && (anyok || (answer.answer ? answer.answer === current.kpAnswers[kpid] : answer.ok)));

                let value = prevState?.rakoef?.[kp?.ra ?? 1] ?? 0;
                let extrabonuses = 0;
                // first and shared points
                let visitstats: string[] = [];
                if ((prevState.firstbonus || prevState.sharedrakoef) && prevState.allTeamAnswers) {
                    visitstats = Object.entries(prevState.allTeamAnswers).map(([atid, ta]) => {
                        return {
                            tid: atid,
                            aeg: (ta[kpid] && (anyok || ta[kpid].answer ? ta[kpid].answer === current.kpAnswers[kpid] : ta[kpid].ok))
                                ? (ta[kpid].aeg ?? dayjs().valueOf()) : 0
                        }
                    })
                        .filter(v => v.aeg > 0)
                        .sort((l, r) => l.aeg - r.aeg)
                        .map(v => v.tid);
                    if (prevState.sharedrakoef) {
                        let sharedrakoefids: { [sid: string]: true } = {};
                        prevState.sharedrakoef.split(",").forEach((sid) => (sharedrakoefids[sid] = true));
                        value = Math.floor(value / visitstats.length);
                    }
                    if (prevState.firstbonus) {
                        const idx = visitstats.indexOf(tid);
                        if (idx !== -1 && (idx + 1) < prevState.firstbonus.length) {
                            extrabonuses += prevState.firstbonus[idx + 1] * (prevState.firstbonusismultiplier ? value : 1);
                            console.log(extrabonuses, prevState.firstbonus[idx], prevState.firstbonus, idx);
                        }
                    }
                }
                // first and shared points -- end --

                let bonus = 0, penalty = 0;
                if (!kp.tyhistatud) {
                    if (correct || prevState.answerscores) bonus += value;
                    if (answer && !correct) {
                        if (kp.wrongpenalty) { penalty += kp.wrongpenalty } else {
                            if (prevState.wrongpenaltyenabled) {
                                penalty += value * (prevState?.wrongcoef ?? 1)
                            }
                        }
                    }
                    if (!answer && kp.kohustuslik) {
                        penalty += value * 2;
                    }
                }
                bonus += extrabonuses; // Currently first bonus.
                const kpdata = prevState?.kpData?.[kpid];
                const kpanswer = prevState?.kpAnswers?.[kpid];
                return {
                    id: kpid,
                    kp: kp,
                    kpdata,
                    kpanswer,
                    correctanswer: kpdata?.responses ? (kpanswer ? kpdata.responses[kpanswer] : "-- Missing answer --") : anyok ? "" : kpanswer ?? "-- Missing answer --", // Make it visible if incomplete kp
                    aeg: answer?.aeg,
                    marked: Boolean(answer),
                    response: kpdata?.responses ? (answer?.answer ? kpdata.responses[answer.answer] ?? "-- Bad resposne --" : "") : answer?.answer,
                    correct,
                    value,
                    bonus,
                    penalty,
                    points: bonus - penalty,
                }
            })
        };
        // TODO add bad KP's
        if (shallowEqual(result, prevResult)) {
            return;
        }
        prevResult = result;

        let totals = result.kps.reduce((acc, { marked, correct, points, bonus, penalty }) => {
            if (marked) acc.marks++;
            if (correct) acc.correct++;
            if (marked && !correct) acc.wrong++;
            acc.kokku += points;
            acc.bonus += bonus;
            acc.penalty += penalty;
            return acc;
        },
            {
                marks: 0,
                correct: 0,
                wrong: 0,
                kokku: 0,
                bonus: 0,
                penalty: 0
            })
        const respresult = {
            ...result,
            ...totals,
        }
        callback(respresult);
    };
    calculate();
    const unsub = store.subscribe(calculate);
    return unsub;
}

export const useValikResult = (props: { klass: string }) => {
    const { klass } = props;
    console.log("running useValikResult");
    const allteams = useTSelector(s => s.teamsList);

    const teams = useResultDeepEqMemo(() => {
        console.log("Calculating new class teams")
        return Object.entries(allteams).filter(([tid, t]) => t.klassid?.[klass]).map(([tid, t]) => tid);
    }, [allteams, klass])

    useEffect(() => {
        const unsubs = teams.map((tid) => getValikTeamResult(tid, (r) => {
            console.log("result for team", tid, r);
        }))
        return () => {
            unsubs.forEach(u => u());
        }
    }, [teams]);
    useTraceUpdate(props);
    return teams;
}