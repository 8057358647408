import React, { useCallback, useEffect, useRef, useState } from "react";
import { RootState, useTSelector } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from "react-i18next";
import { closeDialog, openDialog } from "rx/dialogsSlice";
import memoizeOne from "memoize-one";
import { TeamList } from "rx/fbListSlices";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
  listAll,
  uploadString,
  StorageReference,
  deleteObject,
} from "@firebase/storage";
import {
  child,
  DatabaseReference,
  get,
  getDatabase,
  ref,
  set,
} from "firebase/database";
import { declocdata } from "utils/OldTracksDataListener";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { SlideUpTransition } from "utils/DialogUtils";
import { DisplayNameOfUid } from "./EventsManager";
import { enqueueSnackbar } from "notistack";
import GpsStateContent from "./GpsStateContent";
import { isMobile } from "react-device-detect";
import ChangesLogDialogContents from "./ChangesLogDialogContents";

export const archiveprefix = "https://archive.nutilogi.ee";

const DevIdsDialogContentEv = ({ evid }: { evid: string }) => {
  const evlist = useRFirebaseDb<{ [tid: string]: TeamList }>("/teams/" + evid + "/list");
  const trackstat = useRFirebaseDb<{ [tid: string]: { [devid: string]: { count: number } } }>("/teams/" + evid + "/trackstat");

  return <Table>
    <TableHead>
      <TableRow>
        <TableCell>team id</TableCell>
        <TableCell>Nimi</TableCell>
        <TableCell>Dev id's</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {evlist && Object.entries(evlist).map(([tid, tv]) => <TableRow key={tid}>
        <TableCell>{tid}</TableCell>
        <TableCell>{tv.name}</TableCell>
        <TableCell>{tv.devs ? Object.keys(tv.devs).map(d => d) : (trackstat?.[tid] && <Button onClick={() => {
          const keys = Object.keys(trackstat?.[tid] ?? {});
          for (let k of keys) {
            //console.log(`/teams/${evid}/list/${tid}/devs/${k}`);
            set(ref(getDatabase(), `/teams/${evid}/list/${tid}/devs/${k}`), "X");
          }
        }}>
          Assign
        </Button>)}</TableCell>
      </TableRow>)}
    </TableBody>
  </Table>
}

const DevIdsDialogContent = () => {
  const evid = useTSelector(s => s.eventId);

  if (!evid)
    return <div>Need event</div>;
  else
    return <DevIdsDialogContentEv evid={evid} />
}

const deleteInStorage = async (
  ref: StorageReference,
  setMessage: (v: string) => void
) => {
  let hassubfolders = false;
  const all = await listAll(ref);
  for (let p of all.prefixes) {
    await deleteInStorage(p, setMessage);
    hassubfolders = true;
  }
  for (let it of all.items) {
    await deleteObject(it).catch((e) => {
      console.log("Delete of object failed:", e);
      enqueueSnackbar(it.name + " Failed to delete in storage", {
        variant: "error",
      });
    });
    setMessage(`Deleted ${it.fullPath}`);
  }
  if (hassubfolders) {
    enqueueSnackbar(`Folder might need deletion: ${ref.fullPath}`, {
      variant: "warning",
    });
    console.log(`Folder might need deletion: ${ref.fullPath}`);
  }
};

const hasA2AllFiles = async (
  ref: StorageReference,
  setMessage: (v: string) => void
) => {
  const all = await listAll(ref);
  for (let p of all.prefixes) {
    const subresult = await hasA2AllFiles(p, setMessage);
    if (!subresult) return false;
  }
  for (let it of all.items) {
    let url = it.fullPath.replace(new RegExp("^archive"), archiveprefix);
    const a2f = await fetch(url)
      .then((r) => r.arrayBuffer())
      .catch((e) => {
        console.log("fail", e);
        enqueueSnackbar(it.fullPath + " does not exist on A2", {
          variant: "error",
        });
        return null;
      });
    if (a2f === null) return false;

    const a1f = await getDownloadURL(it)
      .then((url) => fetch(url))
      .then((r) => r.arrayBuffer());

    if (a1f.byteLength !== a2f.byteLength) {
      enqueueSnackbar(
        `${it.fullPath} has different sizes on A1 and A2: ${a1f.byteLength},${a2f.byteLength}`,
        {
          variant: "error",
        }
      );
      return false;
    }
    const view1 = new DataView(a1f);
    const view2 = new DataView(a2f);
    for (let i = 0; i < a1f.byteLength; i++) {
      if (view1.getUint8(i) !== view2.getUint8(i)) {
        enqueueSnackbar(it.fullPath + " has different content on A1 and A2", {
          variant: "error",
        });
        return false;
      }
    }
    setMessage(it.fullPath + " is OK");
  }
  return true;
};
const doRemoveA1 = async (
  sref: StorageReference,
  setMessage: (v: string) => void
) => {
  if (await hasA2AllFiles(sref, setMessage)) {
    const evref = ref(getDatabase(), `events/${sref.name}`);
    const av = await get(evref);
    if (!av.exists()) {
      enqueueSnackbar(sref.name + " does not have event entry", {
        variant: "error",
      });
      return false;
    }
    if (av.val().arch !== "a2") {
      await set(child(evref, "arch"), "a2");
      setMessage("Did set event arch to A2");
    }
    await deleteInStorage(sref, setMessage);
    return true;
  }
  return false;
};

type WebClientsType = {
  [key: string]: {
    agent: string;
    connected: string;
    eventId: string;
    uid: string;
  };
};

const A1ArchiveCleanupDialog: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const [data, setData] = useState<StorageReference[]>([]);
  const [message, setMessage] = useState("");
  const [removeselected, setRemoveSelected] = useState(false);
  const [selected, setSelected] = useState<{ [k: string]: StorageReference }>(
    {}
  );
  const { t } = useTranslation();
  let multideleterunning = useRef(false);

  useEffect(() => {
    const sref = storageRef(getStorage(), "/archive");
    let unmounted = false;
    listAll(sref).then((l) => {
      if (unmounted) return;
      setData(l.prefixes);
    });
    return () => {
      unmounted = false;
    };
  }, []);
  const clicker = useCallback(
    async (v: StorageReference) => {
      const result = await doRemoveA1(v, setMessage);
      if (result) {
        setData(data.filter((f) => f.name !== v.name));
        setMessage(`Deletion of ${v.name}in A1 complete`);
      } else setMessage("");
    },
    [setMessage, setData, data]
  );

  if (removeselected && !multideleterunning.current) {
    multideleterunning.current = true;
    const ent = Object.entries(selected);
    if (ent.length === 0) {
      setTimeout(() => {
        multideleterunning.current = false;
        setSelected({});
        setRemoveSelected(false);
      });
    } else {
      clicker(ent[0][1]).then(() => {
        multideleterunning.current = false;
        let hmm: any = Object.assign({}, selected);
        delete hmm[ent[0][0]];
        setSelected(Object.assign(hmm));
      });
    }
  }

  return (
    <>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Button
                    variant="contained"
                    disabled={
                      removeselected || Object.keys(selected).length === 0
                    }
                    onClick={() => {
                      setRemoveSelected(true);
                    }}
                  >
                    Remove A1
                  </Button>
                </TableCell>
                <TableCell>Nr</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((v, idx) => {
                  return (
                    <TableRow key={v.name}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          onChange={(ev, checked) => {
                            if (checked) {
                              setSelected(
                                Object.assign({}, selected, { [v.name]: v })
                              );
                            } else {
                              let hmm: any = Object.assign({}, selected);
                              delete hmm[v.name];
                              setSelected(Object.assign(hmm));
                            }
                          }}
                          checked={Boolean(selected[v.name])}
                        />
                      </TableCell>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{v.name}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          disabled={removeselected}
                          onClick={() => {
                            clicker(v);
                          }}
                        >
                          Remove A1
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Snackbar
        key={message}
        open={message.length !== 0}
        autoHideDuration={6000}
        onClose={() => {
          setMessage("");
        }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <DialogActions>
        <Button onClick={onClose}>{t("button.close")}</Button>
      </DialogActions>
    </>
  );
};

const ActiveWebClientsDialogContent: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  let data = useRFirebaseDb("/connectedClients/web") as WebClientsType;
  if (data && data.a) {
    delete data.a;
  }
  return (
    <>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nr</TableCell>
                <TableCell>Connected</TableCell>
                <TableCell>Event</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Agent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                Object.entries(data).map(([k, v], idx) => {
                  return (
                    <TableRow key={k}>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{v.connected}</TableCell>
                      <TableCell>{v.eventId}</TableCell>
                      <TableCell>
                        <DisplayNameOfUid uid={v.uid} />
                      </TableCell>
                      <TableCell>
                        <Tooltip title={v.agent}>
                          <Typography noWrap width={200}>
                            {v.agent}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("button.close")}</Button>
      </DialogActions>
    </>
  );
};

type Teams = {
  [key: string]: TeamList;
};
const brokenColors = (teams: Teams) =>
  Object.values(teams).find((team) => team.col === undefined);
const memoizeBrokenColors = memoizeOne(brokenColors);

const mapState = (state: RootState) => ({
  eventId: state.eventId,
  event: state.event,
  teams: state.teamsList,
  tracks: state.tracks,
});
const mapDispatch = {
  closeDialog: () => closeDialog("debuggerTools"),
  openVersionDist: () => openDialog("versiondist"),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type DebuggerToolsProps = PropsFromRedux & WithTranslation & {};
type DebuggerToolsState = {
  progValue: number;
  recodeTracksDialog: boolean;
  activeClientsDialog: boolean;
  a1ArchiveCleanup: boolean;
  gpsState: boolean;
  changesLogDialog: boolean;
  devidsDialog: boolean;
};
class DebuggerTools extends React.Component<
  DebuggerToolsProps,
  DebuggerToolsState
> {
  state: DebuggerToolsState = {
    progValue: 0,
    recodeTracksDialog: false,
    activeClientsDialog: false,
    a1ArchiveCleanup: false,
    gpsState: false,
    changesLogDialog: false,
    devidsDialog: false,
  };

  reencodeTrack = (k: string, team: TeamList) => {
    Object.keys(team.devs).forEach((devid) => {
      const fref = storageRef(
        getStorage(),
        `/tracks/${this.props.eventId}/${k}-${devid}-finished.data`
      );
      getDownloadURL(fref).then((url) => {
        fetch(url)
          .then((r) => r.arrayBuffer())
          .then((fdata) => {
            let fview = new Uint8Array(fdata);
            const locdata = declocdata(fview);
            locdata.forEach((ent) => {
              const points = ent.points;
              if (points.length === 0) return;

              var i = 0;
              var prev = 0;
              while (i < points.length) {
                if (points[i].t < prev) {
                  console.log("Bad order");
                }
                prev = points[i].t;
                i++;
              }
            });

            return locdata;
          });
      });
    });
    console.log("Doing recode track", k, team);
  };

  fixColorsInArchive = () => {
    if (!this.props.eventId) return;
    const sref = storageRef(getStorage(), `archive/${this.props.eventId}`);
    getDownloadURL(storageRef(sref, "evdata.json"))
      .then((url) => fetch(url))
      .then((r) => r.json())
      .then(async (evdata) => {
        let cols = (await get(ref(getDatabase(), "conf/colors"))).val();
        let colid = 0;
        Object.values(evdata.teams.list as Teams).forEach((team) => {
          let setcol = cols[colid++];
          if (colid >= cols.length) colid = 0;
          team.col = setcol;
        });
        uploadString(
          storageRef(sref, "evdata.json"),
          JSON.stringify(evdata),
          undefined,
          { contentType: "application/json" }
        );
      });
  };
  fixLatLngInArchive = (evdata: any) => {
    if (!evdata["eventsdata"]) return false;
    if (!evdata["eventsdata"]["kpdata"]) return false;
    let changed = false;
    Object.values(evdata["eventsdata"]["kpdata"]).forEach((kp: any) => {
      if (kp["loc"] === undefined) return;
      if (kp["loc"]["lon"]) {
        changed = true;
        kp["loc"]["lng"] = kp["loc"]["lon"];
        delete kp["loc"]["lon"];
      }
    });
    return changed;
  };
  fixArchives = async () => {
    const archref = storageRef(getStorage(), "archive");
    const list = await listAll(archref);
    const total = list.prefixes.length;
    let done = 0;
    for (const p of list.prefixes) {
      const url = await getDownloadURL(storageRef(p, "evdata.json"));
      const evdata = await fetch(url).then((v) => v.json());
      if (this.fixLatLngInArchive(evdata)) {
        console.log("changed event", evdata);
        uploadString(storageRef(p, "evdata.json"), JSON.stringify(evdata));
      }

      done++;
      console.log(done, total);
      this.setState({ progValue: (100 / total) * done });
    }
  };

  openFB = (ref: DatabaseReference) => () => {
    window.open(ref.toString(), "_blank");
  };

  render() {
    const { t, eventId } = this.props;
    let brokenColors = false;
    let archiveFixingEnabled = false;
    if (this.props.event.arch) {
      if (memoizeBrokenColors(this.props.teams)) brokenColors = true;
    }
    const fb = getDatabase();
    return (
      <>
        <DialogContent>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({ recodeTracksDialog: true });
            }}
          >
            Recode tracks
          </Button>
          {brokenColors && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.fixColorsInArchive}
            >
              Fix colors in archive
            </Button>
          )}
          {archiveFixingEnabled && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.fixArchives}
            >
              Fix archives
            </Button>
          )}
          <Box m={2}>
            <LinearProgress
              value={this.state.progValue}
              variant="determinate"
            />
          </Box>

          <List dense>
            {eventId && (
              <>
                <ListItem>
                  <Button
                    onClick={this.openFB(ref(fb, `/eventsdata/${eventId}`))}
                  >
                    Events Data
                  </Button>
                </ListItem>
                <ListItem>
                  <Button onClick={this.openFB(ref(fb, `/events/${eventId}`))}>
                    Events List
                  </Button>
                </ListItem>
                <ListItem>
                  <Button onClick={this.openFB(ref(fb, `/teams/${eventId}`))}>
                    Teams
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    onClick={() => this.setState({ changesLogDialog: true })}
                  >
                    Changes log
                  </Button>
                </ListItem>
              </>
            )}
            <ListItem>
              <Button onClick={this.openFB(ref(fb, `/eventcodes`))}>
                Event codes
              </Button>
            </ListItem>
            <ListItem>
              <Button onClick={() => this.setState({ devidsDialog: true })}>
                Missing devids
              </Button>
            </ListItem>
            <ListItem>
              <Button
                variant="contained"
                onClick={() => this.props.openVersionDist()}
              >
                Versioin distribution
              </Button>
            </ListItem>
            <ListItem>
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({ activeClientsDialog: true });
                }}
              >
                Active webclients
              </Button>
            </ListItem>
            <ListItem>
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({ a1ArchiveCleanup: true });
                }}
              >
                A1 archive cleanup
              </Button>
            </ListItem>
            <ListItem>
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({ gpsState: true });
                }}
              >
                GPS state
              </Button>
            </ListItem>
          </List>
        </DialogContent>

        <Dialog open={this.state.recodeTracksDialog}>
          <DialogContent>
            <List>
              {Object.entries(this.props.teams)
                .sort(
                  ([ak, ae], [bk, be]) =>
                    ae.name?.localeCompare(be?.name || "") || 0
                )
                .map(([k, e]) => {
                  return (
                    <ListItemButton
                      key={k}
                      onClick={() => this.reencodeTrack(k, e)}
                    >
                      <ListItemText primary={e.name} />
                    </ListItemButton>
                  );
                })}
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ recodeTracksDialog: false });
              }}
            >
              {t("button.close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="xl"
          open={this.state.activeClientsDialog}
          onClose={() => {
            this.setState({ activeClientsDialog: false });
          }}
          TransitionComponent={SlideUpTransition}
        >
          <DialogTitle>Active web clients</DialogTitle>
          <ActiveWebClientsDialogContent
            onClose={() => {
              this.setState({ activeClientsDialog: false });
            }}
          />
        </Dialog>
        <Dialog
          maxWidth="xl"
          open={this.state.a1ArchiveCleanup}
          onClose={() => {
            this.setState({ a1ArchiveCleanup: false });
          }}
          TransitionComponent={SlideUpTransition}
        >
          <DialogTitle>A1 archive cleanup</DialogTitle>
          <A1ArchiveCleanupDialog
            onClose={() => {
              this.setState({ a1ArchiveCleanup: false });
            }}
          />
        </Dialog>
        <Dialog
          maxWidth="xl"
          fullWidth
          fullScreen={isMobile}
          open={this.state.gpsState}
          onClose={() => {
            this.setState({ gpsState: false });
          }}
          TransitionComponent={SlideUpTransition}
        >
          <DialogTitle>GPS state</DialogTitle>
          <GpsStateContent />
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                this.setState({ gpsState: false });
              }}
            >
              {t("button.close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.devidsDialog} fullWidth maxWidth="xl">
          <DialogTitle>DevID's</DialogTitle>
          <DevIdsDialogContent />
        </Dialog>
        <Dialog open={this.state.changesLogDialog} fullWidth maxWidth="xl">
          <DialogTitle>Changes log</DialogTitle>
          <ChangesLogDialogContents
            onClose={() => {
              this.setState({ changesLogDialog: false });
            }}
          />
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ changesLogDialog: false });
              }}
            >
              {t("button.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connector(withTranslation()(DebuggerTools));
