import { Check } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import dayjs from "dayjs";
import { child, getDatabase, ref } from "firebase/database";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { EventType } from "rx/fbListSlices";
import { execWithProgress } from "rx/saveInProgressSlice";
import { AppDispatch, useTSelector } from "rx/store";
import { fetchUidData } from "rx/uidmapSlice";
import { archiveEvent, clearEvent, restoreDeleted } from "utils/archive";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { useNL } from "utils/NLContext";

export const useAppDispatch: () => AppDispatch = useDispatch;

export const DisplayNameOfUid: React.FC<{ uid: string }> = ({ uid }) => {
  const uidmap = useTSelector((state) => state.uidmap);
  const dispatch = useAppDispatch();
  const entry = uidmap[uid];
  if (!entry) {
    dispatch(fetchUidData({ uids: [uid], eventid: "TallinaTest" }));
    return <span>{uid}</span>;
  }
  return <span>{entry.displayName || entry.email || entry.phoneNumber}</span>;
};

const ArchiveProgressDialog: React.FC<{
  archiveid: string | undefined;
  unarchiveid: string | undefined;
  onDone: () => void;
}> = ({ archiveid, unarchiveid, onDone }) => {
  const [title, setTitle] = useState(
    archiveid ? `Archiving ${archiveid} ...` : `Unarchiving ${unarchiveid} ...`
  );
  const [progressValue, setProgressValue] = useState(0);
  const archiveInProgress = useRef<boolean>(false);
  const [progressMsg, setProgressMsg] = useState("");

  //const progressMsg = useRef<string>("");
  const { fbSet, fbRemove } = useNL();
  const dispatch = useAppDispatch();
  const updateProgress = useCallback((value: number, msg?: string) => {
    if (msg) {
      setProgressMsg((state) => {
        return msg + " - DONE\n" + state;
      });
    }
    //if (msg) progressMsg.current = msg + " - DONE\n" + progressMsg.current;
    setProgressValue(value);
  }, []);

  useEffect(() => {
    if (archiveid) {
      if (archiveInProgress.current) return;
      archiveInProgress.current = true;
      dispatch(
        execWithProgress(async () =>
          archiveEvent(archiveid, "archive", updateProgress)
            .then(async (wassuccess) => {
              if (!wassuccess) return Promise.resolve();
              setProgressValue(0);
              setTitle(`Cleaning ${archiveid} ...`);
              await clearEvent(archiveid, updateProgress, false);
              return await fbSet(`/events/${archiveid}/arch`, "a1");
            })
            .then(() => onDone())
        )
      );
    }
    if (unarchiveid) {
      dispatch(
        execWithProgress(async () => {
          const r = await restoreDeleted("archive", unarchiveid, true);
          if (r) fbRemove(`/events/${unarchiveid}/arch`);
          onDone();
        })
      );
    }
  }, [
    archiveid,
    dispatch,
    fbRemove,
    fbSet,
    onDone,
    unarchiveid,
    updateProgress,
  ]);

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <LinearProgress value={progressValue} variant="determinate" />
        <TextField
          fullWidth
          multiline
          value={progressMsg}
          slotProps={{
            input: {
              readOnly: true,
            },
          }}
        />
      </DialogContent>
    </>
  );
};
const EventsManager: React.FC = () => {
  const events = useRFirebaseDb<{ [evid: string]: EventType }>("events");
  const unpaidevents = useRFirebaseDb<{ [evid: string]: string }>(
    "admin/unpaid"
  );
  const uidmap = useTSelector((state) => state.uidmap);
  const [unarchiveevid, setUnarchiveevid] = useState<string>();
  const [archiveevid, setArchiveevid] = useState<string>();
  const dispatch = useAppDispatch();
  const { fbSet, fbRemove } = useNL();

  const eventslist = useMemo(() => {
    if (!events) return [];
    const evlist = Object.entries(events).map(([evid, evdata]) => {
      const owner = Object.entries(evdata.admins || {}).find(
        ([uid, type]) => type === "owner"
      );
      return Object.assign(
        { id: evid, owner: owner ? owner[0] : "No owner" },
        evdata
      );
    });
    return evlist;
  }, [events]);
  const columns: GridColDef<(typeof eventslist)[number]>[] = [
    {
      field: "starttime",
      headerName: "Date",
      filterable: false,
      width: 150,
      renderCell: (par) => {
        return dayjs(par.value).format("DD. MMM YYYY");
      },
    },
    { field: "name", headerName: "Name", width: 300 },
    {
      field: "owner",
      headerName: "Owner",
      width: 300,
      valueGetter: (value) => {
        const uentry = uidmap[value];
        if (uentry)
          return (
            uentry.displayName ||
            uentry.email ||
            uentry.phoneNumber ||
            "empty user"
          );
        dispatch(fetchUidData({ uids: [value], eventid: "--" }));
        return value;
      },
    },
    {
      field: "unpaid",
      headerName: "Unpaid",
      valueGetter: (_, row) => {
        if (unpaidevents === undefined) return undefined;
        return unpaidevents && unpaidevents[row.id] ? "unpaid" : "paid";
      },
      renderCell: (param) => {
        if (param.value === undefined) return null;
        const pref = child(ref(getDatabase(), "/admin/unpaid/"), param.row.id);
        return param.value === "unpaid" ? (
          <Chip
            onClick={() => {
              fbRemove(pref, param.row.name + " marked as unpaid");
            }}
            variant="outlined"
            color="error"
            sx={{ color: "red" }}
            label="Unpaid"
            size="small"
          />
        ) : (
          <Chip
            onClick={() => {
              fbSet(
                pref,
                "Manual: " + dayjs().toISOString(),
                param.row.name + " marked as paid"
              );
            }}
            variant="outlined"
            color="info"
            sx={{ color: "green" }}
            label="Ok"
            size="small"
            icon={<Check />}
          />
        );
      },
    },
    {
      field: "open",
      headerName: "Open",
      renderCell: (par) => {
        return (
          <Button
            onClick={() => {
              window
                .open("https://nutilogi.eu/" + par.row.id, "_evmanevent")
                ?.focus();
            }}
          >
            Open
          </Button>
        );
      },
    },
    {
      field: "result",
      headerName: "Result",
      renderCell: (par) => {
        return (
          <Button
            onClick={() => {
              window
                .open("https://nutilogi.eu/" + par.row.id + "/result", "_evmanevent")
                ?.focus();
            }}
          >
            Result
          </Button>
        );
      },
    },
    {
      field: "archived",
      headerName: "Archived",
      width: 150,
      align: "center",
      renderCell: (param) => {
        const evid = param.row.id;
        const text = param.row.arch ? "Unarchive" : "Archive";
        const color = param.row.arch ? "inherit" : "primary";
        const onClick = param.row.arch
          ? async () => {
            setUnarchiveevid(evid);
          }
          : async () => {
            setArchiveevid(evid);
          };
        return (
          <Button variant="contained" color={color} onClick={onClick}>
            {text}
          </Button>
        );
      },
    },
  ];
  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={eventslist}
        columns={columns}
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
          sorting: {
            sortModel: [{ field: "starttime", sort: "desc" }],
          },
        }}
      />
      <Dialog
        open={Boolean(archiveevid || unarchiveevid)}
        maxWidth="sm"
        fullWidth
      >
        <ArchiveProgressDialog
          archiveid={archiveevid}
          unarchiveid={unarchiveevid}
          onDone={() => {
            setArchiveevid(undefined);
            setUnarchiveevid(undefined);
          }}
        />
      </Dialog>
    </div>
  );
};

export default EventsManager;
