import {
  Grid,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
  Icon,
  TextFieldProps,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import React, { useState } from "react";
import FBSwitch from "./FBSwitch";
import { useTranslation } from "react-i18next";
import { useNL } from "utils/NLContext";
import { useFirebaseDb } from "utils/FirebaseDbWrapper";
import Check from "@mui/icons-material/Check";
import store from "rx/store";

const SimpleTextEdit: React.FC<
  TextFieldProps & { onComplete: (v: string) => void }
> = (props) => {
  const [fvalue, setValue] = useState(props.value as string);
  const { value, onComplete, ...textfieldprops } = props;

  return (
    <TextField
      id="editclassname"
      value={fvalue}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      {...textfieldprops}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={fvalue.trim().length === 0}
                onClick={() => {
                  /*
                                    fbSet(
                                      `/eventsdata/${eventId}/data/ly/${lyid}/name`,
                                      editLyName,
                                      t("ly.namechanged")
                                    );
                                    setEditLyName("");
                                    setEditLyId(undefined);*/
                  props.onComplete(fvalue);
                }}
                size="large"
              >
                <Check />
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
};
const EventSettingsClasses: React.FC<{ eventId: string }> = ({ eventId }) => {
  const fbdata = `/eventsdata/${eventId}/data/klassid`;
  const { t } = useTranslation();
  const { fbSet, fbRemove } = useNL();
  const [newClass, setNewClass] = useState("");
  const [fbstate] = useFirebaseDb(fbdata);
  const [editClassName, setEditClassName] = useState<string>();

  const classexists =
    fbstate.value && Object.keys(fbstate.value).includes(newClass);
  // TODO: Refactor klassid 
  //  Praegu lisatakse klassid nii et key nimi on klassi nimi ja vaartus on bool.
  //  Miks ei ole nii et key on midagi suvalist ja vaartuseks on tegelik klassi nimi. (Nii oligiv arem ma ei tea miks selle ymber muutnud olen :( ))
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="flex-start"
      direction="row"
    >
      <Grid item xs={12}>
        <TextField
          label={t("evclasses.addlabel")}
          id="addclass"
          value={newClass}
          error={classexists}
          helperText={classexists && t("evclasses.err.duplicate")}
          onChange={(event) => {
            setNewClass(event.target.value);
          }}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Add class"
                    disabled={classexists || newClass.trim().length === 0}
                    onClick={() => {
                      fbSet(
                        fbdata + "/" + newClass,
                        true,
                        t("evclasses.classadded")
                      );
                      setNewClass("");
                    }}
                    size="large"
                  >
                    <Add />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      </Grid>
      <Grid item>
        <Table stickyHeader aria-label="classes table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("evclasses.title.class")}</TableCell>
              <TableCell>{t("evclasses.title.autojoin")}</TableCell>
              <TableCell>{t("evclasses.title.delete")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fbstate.value &&
              Object.entries(
                fbstate.value as { [key: string]: boolean | string }
              ).map(([clname, param]) => (
                <TableRow key={clname}>
                  <TableCell>
                    {editClassName === clname ? (
                      <SimpleTextEdit
                        value={clname}
                        onComplete={(newname) => {
                          if (newname !== clname) {
                            fbSet(`${fbdata}/${newname}`, param);
                            fbRemove(`${fbdata}/${clname}`);
                            const teams = store.getState().teamsList;
                            Object.entries(teams).forEach(([tid, tdata]) => {
                              if (tdata.klassid && tdata.klassid[clname]) {
                                fbSet(
                                  `/teams/${eventId}/list/${tid}/klassid/${newname}`,
                                  true
                                );
                                fbRemove(
                                  `/teams/${eventId}/list/${tid}/klassid/${clname}`
                                );
                              }
                            });
                          }
                          setEditClassName(undefined);
                        }}
                      />
                    ) : typeof param === "string" ? (
                      param
                    ) : (
                      <>
                        {clname}
                        <IconButton
                          onClick={() => {
                            setEditClassName(clname);
                          }}
                        >
                          <Icon>
                            <Edit />
                          </Icon>
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <FBSwitch
                      path={fbdata + "/" + clname}
                      disabled={typeof param === "string" ? true : false}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => {
                        fbRemove(
                          fbdata + "/" + clname,
                          t("evclasses.removed"),
                          "rm" + clname
                        );
                      }}
                      size="large"
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default EventSettingsClasses;
