import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2/Grid2";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { getAuth } from "firebase/auth";
import { get, getDatabase, onValue, ref, set } from "firebase/database";
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  DocumentData,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { onOpenNewEvent } from "rx/appStateSlice";
import { closeDialog, openDialog } from "rx/dialogsSlice";
import { setEventId } from "rx/eventIdSlice";
import { EventDoc } from "rx/simpleFirstoreDocSlice";
import { useTSelector } from "rx/store";
import { getArchiveFile } from "utils/ArchiveReader";
import { ArchiveType } from "utils/archiveType";
import { archiveTypeSchema } from "utils/archiveTypeZ";

type CreateEventState = {
  eventName: string;
  eventType: EventType;
  startTime: dayjs.Dayjs;
  startTimeError: string | null;
  endTime: dayjs.Dayjs | null;
  endTimeError: string | null;
  shortLink: string;
  shortLinkHelper: string | undefined;
  shortLinkIsOk: boolean;
  autoShortLink: boolean;
  template: ArchiveType | undefined;
  templateFile: string | undefined;
  removePermissions: boolean;
};

const performCreateEvent = async (
  state: CreateEventState,
  duplicate?: string
) => {
  const {
    eventType,
    eventName,
    startTime,
    endTime,
    shortLink,
    template,
    removePermissions,
  } = state;

  if (shortLink.length === 0) return null; // Safeguard for not to write bad data to db.

  let admins = {
    [getAuth().currentUser!.uid]: "owner",
  };
  if (template) {
    delete template.eventsdata.issues;
    template.eventsdata.docver = 1;
    if (!removePermissions) {
      Object.assign(admins, template.events.admins);
    }
  }
  let event = Object.assign(template?.events || {}, {
    name: eventName,
    starttime: startTime.valueOf(),
    endtime: endTime?.valueOf(),
    admins: admins,
  });
  let eventdata = Object.assign(
    { data: { type: eventType } },
    template?.eventsdata || {}
  );
  let eventdoc: EventDoc = Object.assign(
    {
      speed: {
        delay1: 10,
        delay2: 5,
        over1: 2,
        over2: 10,
        limit: 90,
      },
    },
    template?.evdoc || {},
    {
      admins: event.admins,
    }
  );

  let archevent: ArchiveType | undefined;
  const db = getDatabase();
  if (duplicate) {
    let oldevent = (await get(ref(db, `/events/${duplicate}`))).val();
    let olddoc: DocumentData | undefined;
    if (oldevent.arch) {
      let archstr = await getArchiveFile(
        duplicate,
        oldevent.arch,
        "evdata.json"
      );
      if (archstr !== null) {
        try {
          archevent = JSON.parse(archstr) as ArchiveType;
          oldevent = archevent.events;
          delete event.arch;
          olddoc = archevent.evdoc;
        } catch (er) {
          console.error("Bad archive file");
          return er;
        }
      }
      console.log(archevent);
    } else
      olddoc = (await getDoc(doc(getFirestore(), "events", duplicate))).data();
    event = Object.assign(oldevent, event);
    if (olddoc) eventdoc = olddoc as EventDoc;
  }

  try {
    await set(ref(db, `/events/${shortLink}`), event);
    await setDoc(doc(getFirestore(), `events/${shortLink}`), eventdoc);

    if (duplicate) {
      let oldeventdata = archevent
        ? archevent.eventsdata
        : (await get(ref(db, `/eventsdata/${duplicate}`))).val();
      set(ref(db, `/eventsdata/${shortLink}`), oldeventdata);
    } else {
      set(ref(db, `/eventsdata/${shortLink}`), eventdata);
    }
  } catch (err) {
    return err;
  }
  return null;
};

const EventTypes = {
  valik: "valik",
  suund: "suund",
  tracking: "tracking",
  paevak: "paevak",
} as const;
type EventType = (typeof EventTypes)[keyof typeof EventTypes];

const CreateEventContent: React.FC<{
  duplicate?: string;
  closeHandler?: () => void;
}> = ({ duplicate, closeHandler }) => {
  const [createStarted, setCreateStarted] = useState<boolean>();
  const [state, setState] = useReducer(
    (state: CreateEventState, newState: Partial<CreateEventState>) => ({
      ...state,
      ...newState,
    }),
    {
      eventName: "",
      eventType: "valik",
      startTime: dayjs().hour(0).minute(0).second(0),
      startTimeError: null,
      endTime: dayjs().hour(23).minute(59).second(0),
      endTimeError: null,
      shortLink: "",
      shortLinkHelper: undefined,
      shortLinkIsOk: true,
      autoShortLink: true,
      template: undefined,
      removePermissions: true,
      templateFile: "",
    }
  );

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const createEvent = useCallback(
    (doConfig: boolean) => {
      setCreateStarted(true);
      performCreateEvent(state, duplicate).then((result) => {
        setCreateStarted(false);
        if (result) {
          enqueueSnackbar(
            t(("createevent.err." + (result as any).code) as any),
            {
              variant: "error",
            }
          );
          return;
        }
        dispatch(onOpenNewEvent());
        dispatch(setEventId(state.shortLink));
        window.history.pushState(null, state.eventName, "/" + state.shortLink);
        if (closeHandler) {
          closeHandler();
        }
        dispatch(closeDialog("createEvent"));

        if (doConfig) {
          dispatch(openDialog("configureEvent"));
        }
        enqueueSnackbar(
          t("snackbar.eventcreated", { eventname: state.eventName }),
          {
            variant: "success",
          }
        );
      });
    },
    [closeHandler, dispatch, duplicate, enqueueSnackbar, state, t]
  );
  /* 
  const createEvent = useCallback(  async () => {
 
  */

  const updateShortLink = useCallback(
    (value: string, disableautoshortlink: boolean = false) => {
      let st: Partial<CreateEventState> = {
        shortLink: value,
        shortLinkHelper: undefined,
        shortLinkIsOk: false,
      };
      if (disableautoshortlink) st.autoShortLink = false;
      setState(st);
      if (value.trim().length > 0) {
        get(ref(getDatabase(), "/events/" + value)).then((s) => {
          if (!s.exists()) {
            setState({ shortLinkIsOk: true });
          } else {
            setState({ shortLinkHelper: t("createevent.err.exists") });
          }
        });
      }
    },

    [t]
  );
  const doSetEventName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (state.autoShortLink)
        updateShortLink(
          event.target.value
            .replace(/ /g, "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
      setState({ eventName: event.target.value });
    },
    [state.autoShortLink, updateShortLink]
  );

  let cantCreate =
    state.eventName.trim().length === 0 ||
    !state.shortLinkIsOk ||
    Boolean(state.startTimeError) ||
    Boolean(state.endTimeError) ||
    createStarted;
  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid>
            {false && (
              <Select
                value={state.eventType}
                onChange={(v) => {
                  setState({ eventType: v.target.value as EventType });
                }}
                inputProps={{
                  name: "type",
                  id: "type-simple",
                }}
              >
                {Object.keys(EventTypes).map((k) => (
                  <MenuItem key={k} value={k}>
                    {t(`eventtype.${k as EventType}` as const)}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
          <Grid>
            <TextField
              required
              value={state.eventName}
              onChange={doSetEventName}
              id="eventName"
              variant="outlined"
              label={t("createevent.label.eventname")}
            />
          </Grid>
          <Grid>
            <TextField
              required
              error={Boolean(state.shortLinkHelper)}
              helperText={state.shortLinkHelper}
              value={state.shortLink}
              onChange={(event) => updateShortLink(event.target.value, true)}
              variant="outlined"
              label={t("createevent.label.shortlink")}
            />
          </Grid>
          <Grid>
            <DateTimePicker
              ampm={false}
              label={t("createevent.label.starttime")}
              value={state.startTime}
              showDaysOutsideCurrentMonth={true}
              onError={(err) => {
                setState({ startTimeError: err });
              }}
              onChange={(date, context) => {
                console.log("onChange");
                if (!date) {
                  console.error("Start can't be null");
                  return;
                }
                if (context.validationError) {
                  setState({
                    startTime: date,
                    startTimeError: context.validationError,
                  });
                } else if (!date.isValid()) {
                  console.log("Bad date provided. Should not happen");
                } else {
                  setState({ startTime: date, startTimeError: null });
                }
              }}
              slotProps={{
                toolbar: {
                  hidden: false,
                },
                actionBar: {
                  actions: ["today"],
                },
                textField: {
                  helperText:
                    state.startTimeError &&
                    t(`starttime.error.${state.startTimeError}` as any),
                },
              }}
              format="DD/MM/YYYY HH:mm"
            />
          </Grid>
          <Grid>
            <DateTimePicker
              ampm={false}
              label={t("createevent.label.endtime")}
              value={state.endTime}
              minDate={state.startTime}
              onChange={(date, context) => {
                if (!date || !date.isValid()) {
                  setState({ endTime: undefined, endTimeError: "noorbadtime" });
                } else {
                  setState({
                    endTime: date,
                    endTimeError: context.validationError,
                  });
                }
              }}
              slotProps={{
                toolbar: {
                  hidden: false,
                },
                actionBar: {
                  actions: ["clear"],
                },
                textField: {
                  helperText:
                    state.endTimeError &&
                    t(`starttime.error.${state.endTimeError}` as any),
                },
              }}
              format="DD/MM/YYYY HH:mm"
            />
          </Grid>

          <Grid size={{ xs: 12 }}>
            <Grid container spacing={2}>
              {!duplicate && (
                <Grid>
                  <Button variant="contained" component="label">
                    {t("button.usetemplate")}
                    <input
                      type="file"
                      hidden
                      onChange={(v) => {
                        if (
                          v.target.files === null ||
                          v.target.files.length === 0
                        )
                          return;
                        const inpfile = v.target.files[0];
                        const fileReader = new FileReader();
                        fileReader.readAsText(inpfile);
                        fileReader.onload = (d) => {
                          if (typeof d.target?.result !== "string") {
                            enqueueSnackbar(t("createevent.badtemplate"), {
                              variant: "error",
                            });
                            return;
                          }
                          try {
                            const valid = archiveTypeSchema.safeParse(
                              JSON.parse(d.target.result)
                            );
                            if (!valid.success) {
                              console.warn(valid.error);
                              throw new Error("Not valid");
                            }
                            setState({
                              template: valid.data as ArchiveType,
                              templateFile: inpfile.name,
                            });
                          } catch (e) {
                            console.warn(e);
                            enqueueSnackbar(t("createevent.badtemplate"), {
                              variant: "error",
                            });
                            return;
                          }
                        };
                      }}
                    />
                  </Button>
                </Grid>
              )}
              <Grid>
                {state.templateFile && (
                  <TextField
                    value={state.templateFile}
                    label={t("createevent.label.templatefile")}
                    variant="standard"
                    slotProps={{
                      htmlInput: { readOnly: true },
                    }}
                  />
                )}
              </Grid>
              <Grid>
                {state.templateFile && (
                  <Tooltip title={t("createevent.tooltip.removepermissions")}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.removePermissions}
                          onChange={(v) => {
                            setState({ removePermissions: v.target.checked });
                          }}
                        />
                      }
                      label={t("createevent.label.removepermissions")}
                    />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (closeHandler) closeHandler();
            dispatch(closeDialog("createEvent"));
          }}
        >
          {t("button.cancel")}
        </Button>
        <Button
          disabled={cantCreate}
          color="primary"
          variant="contained"
          onClick={() => createEvent(false)}
        >
          {t("button.create")}
        </Button>
        <Button
          disabled={cantCreate}
          id="createandconfig"
          color="primary"
          variant="contained"
          onClick={() => createEvent(true)}
        >
          {t("button.createandconfig")}
        </Button>
      </DialogActions>
    </>
  );
};

const CreateEvent: React.FC<{
  duplicate?: string;
  closeHandler?: () => void;
}> = (props) => {
  const uid = useTSelector((state) => state.user.user?.uid);
  const { t } = useTranslation();
  const [hasCreatePermission, setHasCreatePermissions] = useState<boolean>();
  useEffect(() => {
    if (!uid) return;
    return onValue(ref(getDatabase(), `/admin/creators/${uid}`), (snap) => {
      setHasCreatePermissions(snap.exists());
    });
  }, [uid]);
  if (hasCreatePermission === undefined) return <CircularProgress />;
  if (hasCreatePermission) return <CreateEventContent {...props} />;
  else
    return (
      <>
        <DialogContent
          dangerouslySetInnerHTML={{
            __html: t("guide.nocreatorpermission" as any),
          }}
        />
        <DialogActions>
          <Button
            onClick={() => {
              if (props.closeHandler) props.closeHandler();
            }}
          >
            {t("button.close")}
          </Button>
        </DialogActions>
      </>
    );
};

export default CreateEvent;
