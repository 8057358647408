import React, { useState } from "react";
import { DeletedKPEntry } from "./KPData";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import NutiMap from "utils/NutiMap";
import KPIcon from "./KPIcon";
import KPMarker from "utils/KPMarker2";
import { blue, red } from "@mui/material/colors";
import { FlyToLoc, ZoomLevelListener } from "utils/MapUtils";
import { useTranslation } from "react-i18next";
import { child, DatabaseReference, remove, set } from "firebase/database";

const RestoreDeletedKPs: React.FC<{
  deletedkps: { [delid: string]: DeletedKPEntry };
  eventref: DatabaseReference;
  onClose: () => void;
}> = ({ deletedkps, eventref, onClose }) => {
  const [selectedkp, setSelectedKP] = useState(undefined as string | undefined);
  const [dofly, setDoFly] = useState(false);
  const { t } = useTranslation();
  return (
    <Dialog open={true} fullWidth={true} maxWidth={"lg"}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={9} xs={12}>
            <div style={{ height: "40vh", width: "100%", padding: "1" }}>
              <NutiMap>
                {dofly && selectedkp && (
                  <FlyToLoc
                    loc={deletedkps[selectedkp].data?.loc}
                    zoom={14}
                    done={() => setDoFly(false)}
                  />
                )}
                <ZoomLevelListener>
                  {(zoom) => (
                    <>
                      {Object.entries(deletedkps).map(([id, kp]) => {
                        const loc = kp.data?.loc;
                        if (loc === undefined) return null;
                        else
                          return (
                            <KPMarker
                              key={id}
                              kp={kp.kp}
                              kpdata={kp.data}
                              position={loc}
                              color={selectedkp === id ? red.A400 : blue.A400}
                              atzoom={zoom}
                              eventHandlers={{
                                click: () => {
                                  setDoFly(false);
                                  setSelectedKP(id);
                                },
                              }}
                            />
                          );
                      })}
                    </>
                  )}
                </ZoomLevelListener>
              </NutiMap>
            </div>
          </Grid>
          <Grid item sm={3}>
            <Paper
              style={{ maxHeight: "40vh", width: "100%", overflow: "auto" }}
            >
              <List aria-label="kplist">
                {Object.entries(deletedkps).map(([id, kp]) => (
                  <ListItemButton
                    key={id}
                    onClick={() => {
                      setSelectedKP(id);
                    }}
                    onDoubleClick={() => {
                      setDoFly(true);
                      setSelectedKP(id);
                    }}
                    selected={selectedkp === id}
                    autoFocus={selectedkp === id}
                  >
                    <ListItemIcon>
                      <KPIcon kp={kp.kp} color="secondary" fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        kp.kp.nr.toString() + " - " + (kp.data?.desc || "")
                      }
                      secondary={kp.data?.longdesc}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!selectedkp}
          onClick={() => {
            if (!selectedkp) return;
            const kp = deletedkps[selectedkp];
            set(child(eventref, `kp/${kp.id}`), kp.kp);
            if (kp.data) set(child(eventref, `kpdata/${kp.id}`), kp.data);
            if (kp.answer) set(child(eventref, `kpanswer/${kp.id}`), kp.answer);
            remove(child(eventref, "deletedkps/" + selectedkp));
            onClose();
          }}
          variant="contained"
          color="primary"
        >
          {t("button.restore")}
        </Button>
        <Button autoFocus variant="contained" onClick={onClose} color="primary">
          {t("button.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestoreDeletedKPs;
