import { Clear } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useEffect, useState } from "react";

type ListFilterFieldProps<T extends Object> = {
  items: T[];
  setNewList: (useitems: T[]) => void;
  field?: keyof T;
};

// const diacricitRx = /\p{Diacritic}/gu; // Needs es6
const diacricitRx = /[\u0300-\u036f]/g;
// TODO make it more generic. items could be list of any type not strings.
export const SearchField = <T extends Object>(
  props: ListFilterFieldProps<T> & TextFieldProps
) => {
  const { items, setNewList, field, ...textFieldProps } = props;
  const [filterstr, setFilter] = useState("");

  useEffect(() => {
    let newitems = structuredClone(items);
    const nfilterstr = filterstr
      ?.normalize("NFD")
      .replace(diacricitRx, "")
      .toLowerCase();

    if (nfilterstr && nfilterstr.length > 0) {
      if (field) {
        newitems = newitems.filter((r) => {
          const fv = r[field];
          return (
            typeof fv === "string" &&
            fv
              .normalize("NFD")
              .replace(diacricitRx, "")
              .toLowerCase()
              .includes(nfilterstr)
          );
        });
      } else {
        newitems = newitems.filter((r) =>
          Object.values(r).some(
            (v) =>
              typeof v === "string" &&
              v
                .normalize("NFD")
                .replace(diacricitRx, "")
                .toLowerCase()
                .includes(nfilterstr)
          )
        );
      }
    }
    setNewList(newitems);
  }, [filterstr, items, field, setNewList]);

  return (
    <TextField
      {...textFieldProps}
      sx={{ paddingBottom: 2 }}
      value={filterstr}
      onChange={(event) => setFilter(event.target.value)}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear filter"
                size="small"
                onClick={() => setFilter("")}
              >
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
};