import { Divider, Menu, MenuItem, PopoverPosition } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setEditTeamId, setInvestigateTrack } from "rx/appStateSlice";
import store, { useTSelector } from "rx/store";
import { argouid, download } from "utils/SmallUtils";
import { trackToGPX } from "utils/GPXHandlers";
import {
  child,
  getDatabase,
  ref as dbRef,
  set,
} from "firebase/database";
import { openDialogWithParam } from "rx/dialogsSlice";
import { useTeamDelete } from "./TeamDialog";

const TeamContextMenuContents: React.FC<
  { tid: string; onClose: () => void }
> = (props) => {
  const { tid, onClose } = props;
  const team = useTSelector((state) => state.teamsList[tid]);
  const uid = useTSelector((state) => state.user.user?.uid);
  const isAdmin = useTSelector((state) => state.user.eventAccess);
  const eventId = useTSelector((state) => state.eventId);
  const deleteTeamCallback = useTeamDelete(tid);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const teamlistref = dbRef(
    getDatabase(),
    `/teams/${eventId || "-"}/list/${tid}`
  );

  const copyTeamId = () => {
    navigator.clipboard.writeText(tid);
    onClose();
  };
  const openUrl = (url: string) => {
    window.open(url, "_blank");
    onClose();
  };
  const openDbUrl = (group: string) => () => {
    openUrl(
      `https://console.firebase.google.com/u/0/project/nutilogi/database/nutilogi/data/~2Fteams~2F${eventId}~2F${group}~2F${tid}`
    );
  };
  const openStoreUrl = (group: string) => () => {
    openUrl(
      `https://console.firebase.google.com/u/0/project/nutilogi/storage/nutilogi.appspot.com/files/~2F${group}~2F${tid}`
    );
  };
  const doDispatch = (action: any) => () => {
    dispatch(action);
    onClose();
  };
  const downloadGPX = (tid: string) => {
    const state = store.getState();
    download(
      trackToGPX(state.tracks[tid], tid, team.name || "--"),
      team.name + "_track.gpx",
      "applicaiton/gpx+xml"
    );
  };

  return (
    <>
      <MenuItem>
        {t("word.team")}&nbsp; <b>{team.name}</b>
      </MenuItem>
      {isAdmin &&
        Object.keys(team.devs || []).length > 1 && [
          <Divider key="div2" />,
          ...Object.keys(team.devs).map((devid) => (
            <MenuItem
              key={devid}
              selected={team.defaultdev === devid}
              onClick={
                team.defaultdev === devid
                  ? undefined
                  : () => {
                    console.log("setting default");
                    set(child(teamlistref, "defaultdev"), devid);
                  }
              }
            >
              {devid}
            </MenuItem>
          )),
        ]}
      {isAdmin && [
        <Divider key="div1" />,
        <MenuItem
          key="sendmsg"
          onClick={() => {
            dispatch(openDialogWithParam({ dialog: "sendMsg", val: tid }));
            onClose();
          }}
        >
          {t("teamcontext.menu.sendmsg")}
        </MenuItem>,
        <MenuItem key="editteam" onClick={() => dispatch(setEditTeamId(tid))}>
          {t("teamcontext.menu.editteam")}
        </MenuItem>,
        <MenuItem key="downloadgpx" onClick={() => downloadGPX(tid)}>
          {t("teamcontext.menu.downloadgpx")}
        </MenuItem>,
        <Divider key="div2" />,
        <MenuItem key="deleteteam" onClick={deleteTeamCallback}>
          {t("teamcontext.menu.deleteteam")}
        </MenuItem>,
      ]}
      {uid === argouid && [
        <Divider key="div3" />,
        <MenuItem
          key="investtracks"
          onClick={doDispatch(setInvestigateTrack(tid))}
        >
          {t("teamcontext.menu.trackdebug")}
        </MenuItem>,
        <Divider key="div4" />,
        <MenuItem key="teamlogs" onClick={openStoreUrl("log")}>
          Storage: logs
        </MenuItem>,
        <MenuItem key="teamgpx" onClick={openStoreUrl("gpx")}>
          Storage: GPX
        </MenuItem>,
        <MenuItem key="teamdb" onClick={openStoreUrl("db")}>
          Storage: db
        </MenuItem>,
        <MenuItem key="teamList" onClick={openDbUrl("list")}>
          DB: team/list
        </MenuItem>,
        <MenuItem key="teamData" onClick={openDbUrl("data")}>
          DB: team/data
        </MenuItem>,
        <Divider key="div5" />,
        <MenuItem key="teamid" onClick={copyTeamId}>
          Copy teamid: <i> {tid}</i>
        </MenuItem>,
      ]}
    </>
  );
};

const DummyForRerenderTest = () => {
  console.log("rendering dummy");
  return null;
};
const TeamContextMenu: React.FC<{
  tid: string;
  children: (
    onContext: (event: React.MouseEvent<HTMLDivElement>) => void
  ) => React.ReactElement;
}> = (props) => {
  const [pos, setPos] = useState(undefined as PopoverPosition | undefined);
  const onContext = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setPos({ top: event.clientY - 2, left: event.clientX - 4 });
  };
  const onCloseCallback = useCallback(() => {
    setPos(undefined);
  }, [setPos])
  return (
    <>
      <Menu
        open={Boolean(pos)}
        anchorReference="anchorPosition"
        anchorPosition={pos}
        onClose={onCloseCallback}
      >
        <TeamContextMenuContents
          tid={props.tid}
          onClose={onCloseCallback}
        />
        <DummyForRerenderTest />
      </Menu>
      {typeof props.children === "function" ? (
        props.children(onContext)
      ) : (
        <div onContextMenu={onContext}>{props.children}</div>
      )}
    </>
  );
};

export default TeamContextMenu;
